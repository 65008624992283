import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '@falcon/env';
import { BaseApi } from '@falcon/core/api/models';
import { ConfirmationModel, PageableResponsePayloadModel } from '@falcon/shared/models';
import { ConfirmationsRequestParamsModel } from './confirmations-request-params.model';

@Injectable({
  providedIn: 'root',
})
export class ConfirmationService extends BaseApi {
  private readonly CONFIRMATIONS_API_URL = `${environment.apiURL}/confirmations`;

  constructor(private http: HttpClient) {
    super();
  }

  getConfirmations(
    params: ConfirmationsRequestParamsModel
  ): Observable<PageableResponsePayloadModel<ConfirmationModel[]>> {
    return this.http.get<PageableResponsePayloadModel<ConfirmationModel[]>>(
      this.CONFIRMATIONS_API_URL,
      {
        params: this.mapParamsToString(params),
      }
    );
  }

  getPendingConfirmationsCount(params: {
    companyId: string;
    startDate?: string;
    endDate?: string;
  }): Observable<number> {
    return this.http.get<number>(`${this.CONFIRMATIONS_API_URL}/pendingcount`, { params });
  }

  updateConfirmation(confirmationData: ConfirmationModel): Observable<ConfirmationModel> {
    return this.http.put<ConfirmationModel>(
      `${this.CONFIRMATIONS_API_URL}/${confirmationData.id}`,
      confirmationData
    );
  }

  batchConfirm(companyId: string, dateFrom: string, dateTo: string): Observable<any> {
    return this.http.post(`${this.CONFIRMATIONS_API_URL}/batch`, {
      companyId,
      dateFrom,
      dateTo,
    });
  }
}
