import { NgModule } from '@angular/core';

import { AuthStore } from './auth';
import { CreateContractStore } from './create-contract';
import { EditContractStore } from './edit-contract';
import { RootStore } from './root';

@NgModule({
  providers: [RootStore, CreateContractStore, AuthStore, EditContractStore],
})
export class StorageModule {}
