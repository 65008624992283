import { Injectable } from '@angular/core';
import {
  MatSnackBar,
  MatSnackBarConfig,
  MatSnackBarRef,
  TextOnlySnackBar,
} from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { FormSaveActionComponent } from '../../components';

interface BasicSnackbarTranslationModel {
  MESSAGE: string;
  ACTION_BUTTON_TEXT?: string;
}

@Injectable()
export class SnackBarService {
  constructor(private matSnackBar: MatSnackBar, private translateService: TranslateService) {}

  openFormSaveAction(): MatSnackBarRef<FormSaveActionComponent> {
    const config: MatSnackBarConfig = {
      verticalPosition: 'bottom',
      panelClass: 'falcon-form-save-action-panel',
    };

    return this.matSnackBar.openFromComponent(FormSaveActionComponent, config);
  }

  openCopySuccess(): Observable<MatSnackBarRef<TextOnlySnackBar>> {
    return this.openBasicTranslatableSnackbar('SHARED.COPY_SUCCESSFUL_SNACKBAR');
  }

  openMandateSetup(): Observable<MatSnackBarRef<TextOnlySnackBar>> {
    return this.openBasicTranslatableSnackbar('SHARED.MANDATE_SETUP_SNACKBAR');
  }

  openItsmeAccessDenied(): Observable<MatSnackBarRef<TextOnlySnackBar>> {
    return this.openBasicTranslatableSnackbar('SHARED.ITSME_ACCESS_DENIED_SNACKBAR');
  }

  openAppUpdateSuccess(): Observable<MatSnackBarRef<TextOnlySnackBar>> {
    return this.openBasicTranslatableSnackbar('SHARED.APP_UPDATE_SUCCESS_SNACKBAR', {
      duration: 3000,
      panelClass: 'text-center',
    });
  }

  public openBasicTranslatableSnackbar(
    translationPathKey: string,
    config?: MatSnackBarConfig
  ): Observable<MatSnackBarRef<TextOnlySnackBar>> {
    return this.translateService
      .get(translationPathKey)
      .pipe(
        map(({ MESSAGE, ACTION_BUTTON_TEXT }: BasicSnackbarTranslationModel) =>
          this.matSnackBar.open(MESSAGE, ACTION_BUTTON_TEXT, config)
        )
      );
  }
}
