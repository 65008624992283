import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'localizedDate',
})
export class LocalizedDatePipe implements PipeTransform {
  private datePipe: DatePipe;

  constructor(private translateService: TranslateService) {
    this.datePipe = new DatePipe(this.translateService.currentLang);
  }

  transform(value: string | number | Date | null, ...args: any[]): string | null {
    return this.datePipe.transform(value, ...args);
  }
}
