import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { AbstractControl, UntypedFormControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable } from 'rxjs';
import { debounceTime, filter, map, startWith } from 'rxjs/operators';
import { isPlainObject, isString } from 'lodash';

import { DictionaryItem } from '../../models';
import { DebounceTypeEnum } from '../../enums';

@UntilDestroy()
@Component({
  selector: 'falcon-dictionary-item-autocomplete',
  templateUrl: './dictionary-item-autocomplete.component.html',
  styleUrls: ['./dictionary-item-autocomplete.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DictionaryItemAutocompleteComponent implements OnInit {
  @Input() control!: UntypedFormControl;
  @Input() label!: string;
  @Input() items!: DictionaryItem[] | null;
  @Input() requiredErrorLabel!: string;
  filteredItems$!: Observable<DictionaryItem[]>;

  readonly unselectedItemErrorName = 'dictionaryItemIsNotSelected';

  ngOnInit(): void {
    this.control.addValidators(this.dictionaryItemAutocompleteValidator());
    this.control.updateValueAndValidity();

    this.filteredItems$ = this.control.valueChanges.pipe(
      filter(isString),
      debounceTime(DebounceTypeEnum.Typing),
      startWith(''),
      map((searchValue: string) => searchValue.trim().toLowerCase()),
      map((searchValue: string) => {
        if (!this.items) {
          return [];
        }
        if (!searchValue.length) {
          return this.items;
        }

        return this.items
          .filter(item => item.name.toLowerCase().includes(searchValue))
          .sort((i1, i2) => {
            const item1Name = i1.name.toLowerCase();
            const item2Name = i2.name.toLowerCase();

            if (item1Name.startsWith(searchValue) && !item2Name.startsWith(searchValue)) {
              return -1;
            }

            return item1Name.localeCompare(item2Name);
          });
      }),
      untilDestroyed(this)
    );
  }

  dictionaryItemDisplayFn(item: DictionaryItem): string {
    return item?.name;
  }

  private dictionaryItemAutocompleteValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;
      const error = { [this.unselectedItemErrorName]: true };

      return !value || isPlainObject(value) ? null : error;
    };
  }
}
