import { inject } from '@angular/core';
import { CanMatchFn, Router } from '@angular/router';
import { map } from 'rxjs/operators';

import { AppRoutePath } from '@falcon/app/app.routing.model';
import { AuthService } from '@falcon/core/api/auth';
import { AuthStore } from '@falcon/core/storage';

export const unauthorizedGuard: CanMatchFn = () => {
  const authService = inject(AuthService);
  const authStore = inject(AuthStore);
  const router = inject(Router);

  if (authService.isAuthenticated()) {
    if (!authStore.getUser()) {
      authService.loadCurrentUser();
    }

    return authStore.getUserData$().pipe(map(() => true));
  }

  return router.createUrlTree([AppRoutePath.AUTH]);
};
