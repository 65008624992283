<h3 class="text-center">
  {{ 'ADD_EMPLOYEE_ACTIONS.TITLE' | translate }}
</h3>

<mat-action-list class="sheet-actions-group">
  <button *ngIf="!isPayrollSales" mat-list-item (click)="onRegisterEmployee()">
    {{ 'ADD_EMPLOYEE_ACTIONS.MANUALLY_REGISTER' | translate }}
  </button>
  <button *ngIf="canInviteEmployee" mat-list-item (click)="onInviteEmployee()">
    {{ 'ADD_EMPLOYEE_ACTIONS.SEND_INVITE' | translate }}
  </button>
  <button mat-list-item (click)="navigateToInvitationsList()">
    {{ 'ADD_EMPLOYEE_ACTIONS.VIEW_INVITATIONS' | translate }}
  </button>
</mat-action-list>

<button
  mat-raised-button
  color="primary"
  class="falcon-rounded-btn mt-4"
  (click)="bottomSheetRef.dismiss()"
>
  {{ 'GENERAL.CLOSE_WINDOW' | translate }}
</button>
