import { NgModule } from '@angular/core';

import { ApiModule } from './api';
import { I18nModule } from './i18n';
import { StorageModule } from './storage';
import { InterceptorsModule } from './interceptors/interceptors.module';

@NgModule({
  imports: [ApiModule, I18nModule, StorageModule, InterceptorsModule],
})
export class CoreModule {}
