import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
  HostBinding,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { TranslateModule } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import moment from 'moment';

import { DateTimeFormats } from '@falcon/shared/enums';
import { LocalizedDateModule } from '@falcon/shared/pipes';

type WeekType = 'prev' | 'next';

@Component({
  standalone: true,
  imports: [CommonModule, LocalizedDateModule, TranslateModule, MatButtonModule],
  selector: 'falcon-week-paginator',
  templateUrl: './week-paginator.component.html',
  styleUrls: ['./week-paginator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WeekPaginatorComponent implements OnInit {
  private readonly oneWeekDuration = moment.duration(1, 'week');
  readonly weekDateFormat = 'd MMM';

  @Input() weekStart$!: Observable<string>;
  @Output() weekChange = new EventEmitter<string>();

  weeksPeriod$!: Observable<{
    prevWeekStart: string;
    currWeekStart: string;
    nextWeekStart: string;
  }>;

  @HostBinding('class') hostClasses = [
    'd-flex',
    'falcon-bg-white',
    'align-items-center',
    'falcon-button-border-radius',
  ];

  ngOnInit(): void {
    this.weeksPeriod$ = this.weekStart$.pipe(
      map(date => ({
        prevWeekStart: this.getWeekDateByCurrWeek(date, 'prev'),
        currWeekStart: date,
        nextWeekStart: this.getWeekDateByCurrWeek(date, 'next'),
      }))
    );
  }

  changeWeek(currWeek: string, weekType: WeekType): void {
    this.weekChange.emit(this.getWeekDateByCurrWeek(currWeek, weekType));
  }

  private getWeekDateByCurrWeek(currWeek: string, weekType: WeekType): string {
    return moment(currWeek)
      [weekType === 'next' ? 'add' : 'subtract'](this.oneWeekDuration)
      .format(DateTimeFormats.ISO_DATE);
  }
}
