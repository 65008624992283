import { FalconEnvEnum } from './falcon-env.enum';

const BOEMM_LOGIN_PARAMS = {
  identity_provider: 'BoemmAD',
  client_id: '24h6e621sbh44an77b4isjpcpd',
  response_type: 'code',
  scope: ['aws.cognito.signin.user.admin', 'email', 'openid', 'phone', 'profile'].join(' '),
  redirect_uri: 'https://gw.qa.falcon.boemm.eu/v1/signin',
};
const ITSME_LOGIN_PARAMS = {
  identity_provider: 'ItsMeLogin',
  client_id: '24h6e621sbh44an77b4isjpcpd',
  response_type: 'code',
  scope: ['aws.cognito.signin.user.admin', 'email', 'openid', 'phone', 'profile'].join(' '),
  redirect_uri: 'https://gw.qa.falcon.boemm.eu/v1/signin',
};

const ITSME_REGISTER_PARAMS = {
  identity_provider: 'ItsMeRegistration',
  client_id: '24h6e621sbh44an77b4isjpcpd',
  response_type: 'code',
  scope: ['aws.cognito.signin.user.admin', 'email', 'openid', 'phone', 'profile'].join(' '),
  redirect_uri: 'https://gw.qa.falcon.boemm.eu/v1/signin',
};

const boemmLoginParamsStr = new URLSearchParams(BOEMM_LOGIN_PARAMS).toString();
const itsmeLoginParamsStr = new URLSearchParams(ITSME_LOGIN_PARAMS).toString();
const itsmeRegisterParamsStr = new URLSearchParams(ITSME_REGISTER_PARAMS).toString();

export const environment = {
  name: FalconEnvEnum.QA,
  production: true,
  appVersion: require('../../package.json').version,
  apiURL: 'https://gw.qa.falcon.boemm.eu/v1/falcon-api/api',
  publicApiURL: 'https://gw.qa.falcon.boemm.eu/v1/falcon-api/publicapi',
  notificationsApiUrl: 'https://gw.qa.falcon.boemm.eu/v1/falcon-api',
  boemmLoginURL: `https://falcon-qa-v2.auth.eu-central-1.amazoncognito.com/oauth2/authorize?${boemmLoginParamsStr}`,
  itsMeLoginURL: `https://falcon-qa-v2.auth.eu-central-1.amazoncognito.com/oauth2/authorize?${itsmeLoginParamsStr}`,
  itsMeRegisterURL: `https://falcon-qa-v2.auth.eu-central-1.amazoncognito.com/oauth2/authorize?${itsmeRegisterParamsStr}`,
  baseUrl: 'https://qa.falcon.boemm.eu',
  gtmId: 'GTM-TK8WL43',
  featureFlagClientId: '64b55417444f2113552b492a',
  iosAppStoreUrl: 'https://apps.apple.com/app/id324684580', // Since only prod app will be available in AppStore, Spotify app is used for testing,
  androidGooglePlayUrl: 'https://play.google.com/store/apps/details?id=qa.be.worktoday',
};
