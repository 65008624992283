import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '@falcon/env';
import { OfficeModel } from '@falcon/shared/models';
import { BaseApi } from '@falcon/app/core/api/models';
import { OfficesRequestParamsModel } from './offices-request-params.model';

@Injectable({
  providedIn: 'root',
})
export class OfficeService extends BaseApi {
  private readonly OFFICES_API_URL = `${environment.apiURL}/offices`;

  constructor(private http: HttpClient) {
    super();
  }

  getOffices(params?: OfficesRequestParamsModel): Observable<OfficeModel[]> {
    return this.http.get<OfficeModel[]>(this.OFFICES_API_URL, {
      params: params ? this.mapParamsToString(params) : undefined,
    });
  }
}
