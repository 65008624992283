export type DictionaryType =
  | 'statutes'
  | 'countries'
  | 'languages'
  | 'paritaircomites'
  | 'naces'
  | 'blockingreasons'
  | 'travelallowances'
  | 'cancelreasons'
  | 'reasons'
  | 'defaulttaxrates'
  | 'compensationhours'
  | 'transports'
  | 'drivinglicenses'
  | 'dependentpartners'
  | 'maritalstatuses'
  | 'taxlevels'
  | 'notworkedreasons'
  | 'genericstatutes';

export interface DictionaryItem<T = string> {
  code: T;
  name: string;
}

export interface LanguageDictionaryItem extends DictionaryItem {
  primary: boolean;
}

export enum TaxRateCodeEnum {
  ZERO = '0',
  SIX = '6',
  TWELVE = '12',
  TWENTY_ONE = '21',
  ZERO_SHIFTED = '0_SHIFTED',
  ZERO_SHIFTED_EU = '0_SHIFTED_EU',
}

export enum TravelAllowanceCodeEnum {
  NONE = 'NONE',
  SUBSCRIPTION_PRIVATE = 'SUBSCRIPTION_PRIVATE',
  SUBSCRIPTION_PUBLIC = 'SUBSCRIPTION_PUBLIC',
  COMPANY_CAR = 'COMPANY_CAR',
}

export enum ReasonCodeEnum {
  TEMPORAL_EXTRA_WORK = 'TEMPORAL_EXTRA_WORK',
  SUBSTITUTION = 'SUBSTITUTION',
  EXCEPTION_WORK = 'EXCEPTION_WORK',
  INFLOW = 'INFLOW',
}

export enum CompensationHourCodeEnum {
  NONE = 'NONE',
  PAID = 'PAID',
  NOT_PAID = 'NOT_PAID',
}

export enum StatuteCodeEnum {
  LABOUR = 'LABOUR',
  WHITE_COLLAR = 'WHITE_COLLAR',
  SEASONAL = 'SEASONAL',
  FLEX_LABOUR = 'FLEX_LABOUR',
  FLEX_WHITE_COLLAR = 'FLEX_WHITE_COLLAR',
  EXTRA = 'EXTRA',
  WHITE_COLLAR_STUDENT = 'WHITE_COLLAR_STUDENT',
  LABOUR_STUDENT = 'LABOUR_STUDENT',
  WHITE_COLLAR_STUDENT_WORKER = 'WHITE_COLLAR_STUDENT_WORKER',
  LABOUR_STUDENT_WORKER = 'LABOUR_STUDENT_WORKER',
}

export enum GenericStatueCodeEnum {
  JOBSTUDENT = 'JOBSTUDENT',
  FLEX = 'FLEX',
  WHITE_OR_BLUE_COLLAR_WORKER = 'WHITE_OR_BLUE_COLLAR_WORKER',
  EXTRA = 'EXTRA',
  WORKER_STUDENT = 'WORKER_STUDENT',
  SEASONAL ='SEASONAL',
}

export enum CollarCodeEnum {
  WHITE = 'WHITE',
  BLUE = 'BLUE',
}
