<h3 class="text-center font-semibold">
  {{ company.name }}
</h3>

<mat-nav-list class="sheet-actions-group">
  <a mat-list-item [href]="companyMobilePhoneHref">
    {{ 'CONTACT_COMPANY_ACTIONS.CALL' | translate }} {{ formattedPhoneNumber }}
  </a>
  <a mat-list-item [href]="companyWhatsAppHref">
    {{ 'CONTACT_COMPANY_ACTIONS.SEND_WHATSAPP' | translate }}
  </a>
</mat-nav-list>

<button
  mat-flat-button
  color="primary"
  class="falcon-rounded-btn mt-3"
  (click)="contactCompanyActionsRef.dismiss()"
>
  {{ 'GENERAL.CLOSE_WINDOW' | translate }}
</button>
