import {
  Component,
  ChangeDetectionStrategy,
  Input,
  Output,
  ViewChild,
  EventEmitter,
  OnInit,
  AfterViewInit,
  HostBinding,
  ViewEncapsulation,
} from '@angular/core';
import {
  DateRange,
  DefaultMatCalendarRangeStrategy,
  MatCalendar,
  MatCalendarCellClassFunction,
  MAT_DATE_RANGE_SELECTION_STRATEGY,
} from '@angular/material/datepicker';
import { ComponentType } from '@angular/cdk/portal';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable, Subject } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import moment, { Moment } from 'moment';

import { CreateContractDates } from '@falcon/core/storage';
import { ContractModel } from '@falcon/shared/models';

@UntilDestroy()
@Component({
  selector: 'falcon-date-range-picker',
  template: `
    <mat-calendar
      #calendar
      [minDate]="minDate"
      [maxDate]="maxDate"
      [dateClass]="dateClass"
      [selected]="selectedDateRange"
      [headerComponent]="headerComponent"
      (selectedChange)="selectedChange($event)"
    >
    </mat-calendar>
  `,
  styleUrls: ['./date-range-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: MAT_DATE_RANGE_SELECTION_STRATEGY,
      useClass: DefaultMatCalendarRangeStrategy,
    },
  ],
})
export class DateRangePickerComponent implements OnInit, AfterViewInit {
  private readonly _today = moment();

  selectedDateRange: DateRange<Moment> | null = null;

  @Input() contracts!: ContractModel[];
  @Input() set dateRangeValue({ dateFrom, dateTo }: CreateContractDates) {
    this.selectedDateRange = new DateRange(dateFrom, dateTo);
  }
  @Input() minDate: Moment = this.today;
  @Input() maxDate: Moment | null = null;
  @Input() dateClass!: MatCalendarCellClassFunction<Moment>;
  @Input() headerComponent!: ComponentType<any>;
  @Input() update!: Observable<void>;

  @Output() rangeSelectionChange = new EventEmitter<DateRange<Moment | null>>();

  private _periodChange = new Subject<Moment>();
  @Output() periodChange = this._periodChange
    .asObservable()
    .pipe(distinctUntilChanged((date1, date2) => date1.isSame(date2, 'date')));

  @ViewChild('calendar') calendar!: MatCalendar<Moment>;

  @HostBinding('class.falcon-date-range-picker') hostClass = true;

  get today(): Moment {
    return this._today.clone();
  }

  ngOnInit(): void {
    this.update.pipe(untilDestroyed(this)).subscribe(() => {
      this.calendar.updateTodaysDate();
    });
  }

  ngAfterViewInit(): void {
    this.calendar.stateChanges
      .pipe(untilDestroyed(this))
      .subscribe(() => this._periodChange.next(this.calendar.activeDate));
  }

  selectedChange(selectedDate: Moment | null): void {
    let { start, end } = this.selectedDateRange as DateRange<Moment>;
    const isStartDate = !start || end;
    selectedDate = selectedDate as Moment;
    if (isStartDate || selectedDate.isBefore(start)) {
      start = selectedDate;
      end = null;
      this.setMinMaxDates(selectedDate);
    } else {
      if (selectedDate.isSame(start)) {
        return;
      }
      end = selectedDate;
      this.resetMinMaxDates();
    }
    this.selectedDateRange = new DateRange(start, end);
    this.rangeSelectionChange.emit(this.selectedDateRange);
  }

  private setMinMaxDates(selectedDate: Moment): void {
    const currWeekStartDate = selectedDate.clone().startOf('week');
    const currWeekEndDate = selectedDate.clone().endOf('week');
    const currMonthStartDate = selectedDate.clone().startOf('month');
    const currMonthEndDate = selectedDate.clone().endOf('month');

    this.minDate = currWeekStartDate.isAfter(this.today) ? currWeekStartDate : this.today;
    if (currWeekStartDate.isBefore(currMonthStartDate)) {
      this.minDate = currMonthStartDate;
    }
    this.maxDate = currMonthEndDate.isBefore(currWeekEndDate) ? currMonthEndDate : currWeekEndDate;
  }

  private resetMinMaxDates(): void {
    this.minDate = this.today;
    this.maxDate = null;
  }
}
