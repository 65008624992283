import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { filter, Observable } from 'rxjs';

import { AuthStore, RootStore } from '@falcon/core/storage';
import { environment } from '@falcon/env';
import { UserDetailsModel } from '@falcon/shared/models';
import { AppRoutePath } from '@falcon/app/app.routing.model';
import {
  AuthResultModel,
  ConfirmPasswordModel,
  LoginDataModel,
  NewPasswordDataModel,
  ResetPasswordResponseModel,
} from './models';
import { DialogService } from '@falcon/app/shared/services';
import { switchMap } from 'rxjs/operators';

export const AUTH_COOKIE_KEY = 'skey';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private readonly CURRENT_USER_API_URL = `${environment.apiURL}/users/currentuser`;
  readonly AUTH_API_URL = `${environment.publicApiURL}/customers/companies/auth`;

  constructor(
    private http: HttpClient,
    private rootStore: RootStore,
    private authStore: AuthStore,
    private router: Router,
    private dialogService: DialogService
  ) {}

  isAuthenticated(): boolean {
    return !!localStorage.getItem(AUTH_COOKIE_KEY);
  }

  login(loginData: LoginDataModel): Observable<AuthResultModel> {
    return this.http.post<AuthResultModel>(`${this.AUTH_API_URL}/login`, loginData);
  }

  setNewPassword(newPasswordData: NewPasswordDataModel): Observable<AuthResultModel> {
    return this.http.post<AuthResultModel>(`${this.AUTH_API_URL}/setPassword`, newPasswordData);
  }

  resetPassword(username: string): Observable<ResetPasswordResponseModel> {
    return this.http.post<ResetPasswordResponseModel>(`${this.AUTH_API_URL}/resetForgotPassword`, {
      username,
    });
  }

  confirmPassword(confirmPasswordData: ConfirmPasswordModel): Observable<void> {
    return this.http.post<void>(`${this.AUTH_API_URL}/confirmForgotPassword`, confirmPasswordData);
  }

  logoutCognito() {
    return this.http.get<void>(`${environment.apiURL}/customers/auth/logout`);
  }

  logout(): void {
    this.dialogService
      .openGenericConfirmDialog('SHARED.LOGOUT_CONFIRM_DIALOG', {
        data: { iconName: 'warning_circle' },
      })
      .pipe(
        filter(Boolean),
        switchMap(() => this.logoutCognito())
      )
      .subscribe(() => {
        localStorage.removeItem(AUTH_COOKIE_KEY);
        this.rootStore.clean();
        this.authStore.clean();
        this.router.navigateByUrl(AppRoutePath.AUTH);
      });
  }

  loadCurrentUser(): void {
    this.http
      .get<UserDetailsModel>(this.CURRENT_USER_API_URL)
      .subscribe(user => this.authStore.setUser(user));
  }
}
