export enum AppRoutePath {
  ROOT = '',
  COMPANY = 'company',
  CONTRACT = 'contract',
  SEARCH = 'search',
  EMPLOYEE = 'employee',
  CREATE_EMPLOYEE = 'create-employee',
  INVITATIONS = 'invitations',
  INVITE_EMPLOYEE_REGISTRATION = 'invite-employee-registration',
  EMPLOYEE_REGISTRATION = 'employee-registration',
  AUTH = 'auth',
  UPDATE_REQUIRED = 'update-required',
  EMPLOYEE_USER = 'employee-user',
  FLASH = 'flash',
  PWA_DEPRECATION = 'pwa-deprecation',
}

export enum AppRoutePathParam {
  COMPANY_ID = 'companyId',
  EMPLOYEE_ID = 'employeeId',
  INVITATION_ID = 'invitationId',
}
