<mat-dialog-content class="d-flex flex-column py-2">
  <mat-form-field>
    <mat-label>{{ 'ADDRESS.STREET' | translate }}</mat-label>
    <input type="text" autocorrect="off" matInput [formControl]="form.controls.street" />
    <mat-error *ngIf="form.controls.street.hasError('required')">
      {{ 'ADDRESS.VALIDATION_ERRORS.STREET_REQUIRED' | translate }}
    </mat-error>
    <mat-error *ngIf="form.controls.street.hasError('pattern')">
      {{ 'ADDRESS.VALIDATION_ERRORS.STREET_PATTERN' | translate }}
    </mat-error>
  </mat-form-field>

  <mat-form-field>
    <mat-label>{{ 'ADDRESS.STREET_NUMBER' | translate }}</mat-label>
    <input type="text" autocorrect="off" matInput [formControl]="form.controls.streetNumber" />
    <mat-error *ngIf="form.controls.streetNumber.hasError('required')">
      {{ 'ADDRESS.VALIDATION_ERRORS.STREET_NUMBER_REQUIRED' | translate }}
    </mat-error>
    <mat-error *ngIf="form.controls.streetNumber.hasError('pattern')">
      {{ 'ADDRESS.VALIDATION_ERRORS.STREET_NUMBER_PATTERN' | translate }}
    </mat-error>
  </mat-form-field>

  <mat-form-field>
    <mat-label>{{ 'ADDRESS.PO_BOX' | translate }}</mat-label>
    <input type="text" autocorrect="off" matInput [formControl]="form.controls.bus" />
    <mat-error *ngIf="form.controls.bus.hasError('pattern')">
      {{ 'ADDRESS.VALIDATION_ERRORS.PO_BOX_PATTERN' | translate }}
    </mat-error>
  </mat-form-field>

  <mat-form-field>
    <mat-label>{{ 'ADDRESS.POSTAL_CODE' | translate }}</mat-label>
    <input type="text" autocorrect="off" matInput [formControl]="form.controls.postalCode" />
    <mat-error *ngIf="form.controls.postalCode.hasError('required')">
      {{ 'ADDRESS.VALIDATION_ERRORS.POSTAL_CODE_REQUIRED' | translate }}
    </mat-error>
    <mat-error *ngIf="form.controls.postalCode.hasError('pattern')">
      {{ 'ADDRESS.VALIDATION_ERRORS.POSTAL_CODE_PATTERN' | translate }}
    </mat-error>
  </mat-form-field>

  <mat-form-field>
    <mat-label>{{ 'ADDRESS.CITY' | translate }}</mat-label>
    <input type="text" autocorrect="off" matInput [formControl]="form.controls.city" />
    <mat-error *ngIf="form.controls.city.hasError('required')">
      {{ 'ADDRESS.VALIDATION_ERRORS.CITY_REQUIRED' | translate }}
    </mat-error>
    <mat-error *ngIf="form.controls.city.hasError('pattern')">
      {{ 'ADDRESS.VALIDATION_ERRORS.CITY_PATTERN' | translate }}
    </mat-error>
  </mat-form-field>

  <falcon-dictionary-item-autocomplete
    [label]="'ADDRESS.COUNTRY' | translate"
    [items]="countries$ | async"
    [control]="countryAutocompleteControl"
    [requiredErrorLabel]="'ADDRESS.VALIDATION_ERRORS.COUNTRY_REQUIRED' | translate"
  ></falcon-dictionary-item-autocomplete>
</mat-dialog-content>

<button color="primary" class="falcon-rounded-btn" mat-stroked-button mat-dialog-close>
  {{ 'GENERAL.CANCEL' | translate }}
</button>

<button
  color="accent"
  class="falcon-rounded-btn ml-0 mt-2"
  mat-flat-button
  (click)="confirmAddress()"
  [disabled]="!canSubmitForm"
>
  {{ 'GENERAL.CONFIRM' | translate }}
</button>
