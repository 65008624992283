import { Component, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { MatCalendar } from '@angular/material/datepicker';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Moment } from 'moment';

@UntilDestroy()
@Component({
  selector: 'falcon-date-picker-header',
  templateUrl: './date-picker-header.component.html',
  styleUrls: ['./date-picker-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: { class: 'd-flex falcon-color-primary p-1' },
})
export class DatePickerHeaderComponent {
  get periodLabel() {
    return this._calendar.activeDate.format('MMMM YYYY').toUpperCase();
  }

  get prevMonthBtnEnabled(): boolean {
    return this._calendar.minDate
      ? this._calendar.activeDate
          .clone()
          .subtract(1, 'month')
          .isSameOrAfter(this._calendar.minDate, 'date')
      : true;
  }

  get nextMonthBtnEnabled(): boolean {
    return this._calendar.maxDate
      ? this._calendar.activeDate
          .clone()
          .add(1, 'month')
          .isSameOrBefore(this._calendar.maxDate, 'date')
      : true;
  }

  constructor(
    private _calendar: MatCalendar<Moment>,
    private _dateAdapter: DateAdapter<Moment>,
    cdr: ChangeDetectorRef
  ) {
    _calendar.stateChanges.pipe(untilDestroyed(this)).subscribe(() => cdr.markForCheck());
  }

  switchMonth(mode: 'prev' | 'next') {
    this._calendar.activeDate = this._dateAdapter.addCalendarMonths(
      this._calendar.activeDate,
      mode === 'prev' ? -1 : 1
    );
  }
}
