import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export const INTEGER_ERROR_NAME = 'invalidInteger';

export const integerValidator = (): ValidatorFn => {
  return (control: AbstractControl<number | null>): ValidationErrors | null => {
    if (!control.value) return null;

    return Number.isInteger(control.value) ? null : { [INTEGER_ERROR_NAME]: true };
  };
};
