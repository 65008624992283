<nav mat-tab-nav-bar [color]="color" [disableRipple]="true" [disablePagination]="true">
  <a
    mat-tab-link
    class="text-decoration-none"
    *ngFor="let tab of tabs$ | async; trackBy: trackByTabPath"
    [routerLink]="tab.path"
    [active]="tab.isActive"
  >
    <i
      *ngIf="tab.iconName as iconName"
      class="falcon-icon"
      ngClass="falcon-icon-{{ iconName }}"
      [matBadge]="tab.badgeText"
      [matBadgeHidden]="!tab.badgeText"
      [matBadgeOverlap]="false"
      matBadgeSize="small"
      matBadgeColor="accent"
    ></i>
    <span class="tab-label">
      {{ tab.translationLabel | translate }}
    </span>
  </a>
</nav>
