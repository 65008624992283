<mat-form-field>
  <i class="falcon-icon falcon-icon-search ml-3 falcon-color-primary" matIconPrefix></i>

  <input
    matInput
    type="text"
    autocorrect="off"
    [placeholder]="placeholder"
    (input)="onInput($event)"
  />
</mat-form-field>
