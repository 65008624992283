import { DictionaryItem } from './dictionary.model';
import { AddressModel } from './address.model';
import { ConsultantModel } from './consultant.model';
import { FunctionStatuteModel } from './company-function.model';

export interface EmployeeModel {
  id: string;
  name: string;
  firstName: string;
  lastName: string;
  phoneMobileNumber: string;
  phoneHomeNumber: string;
  socialSecurityNumber: string;
  salesCoefficientType: EmployeeSalesCoefficientType;
}

export interface EmployeeDetailsModel {
  id: string;
  name: string;
  firstName: string;
  lastName: string;
  socialSecurityNumber: string;
  contact: ContactModel;
  gender: GenderEnum;
  dateOfBirth: string;
  placeOfBirth: string;
  countryOfBirth: DictionaryItem;
  countryOfOrigin: DictionaryItem;
  officeCode: string;
  consultant: ConsultantModel;
  internalEmployee: boolean;
  onlyDps: boolean;
  status: EmployeeStatusEnum;
  maritalStatus: DictionaryItem;
  dependentPartner: DictionaryItem;
  dependentChildren: number;
  taxLevel: DictionaryItem;
  iban: string;
  studentBalance: StudentBalanceModel;
  transports: TransportModel[];
  isDraft: boolean;
  genericStatute: DictionaryItem & {
    statutes: FunctionStatuteModel[];
  };
}

export enum GenderEnum {
  MALE = 'MALE',
  FEMALE = 'FEMALE',
  OTHER = 'OTHER',
}

export enum EmployeeStatusEnum {
  ACTIVE = 'ACTIVE',
  BLOCKED = 'BLOCKED',
}

export interface ContactModel {
  employeeUuid: string;
  esigning: boolean;
  electronicDocuments: boolean;
  address: AddressModel;
  residenceAddress: AddressModel;
  email: string;
  mobileNumber: string;
  homeNumber: string;
  communicationLanguage: DictionaryItem;
  hasCustomResidencyAddress: boolean;
}

export interface StudentBalanceModel {
  employeeId: string;
  balance: number;
  updatedAt: string;
  changedByConsultant: ConsultantModel;
  changedByContract: {
    id: string;
    allocationId: string;
    contractNumber: string;
  };
}

export interface TransportModel {
  transportCode: string;
  transportName: string;
  radius?: number;
}

export const genders: DictionaryItem<GenderEnum>[] = [
  {
    code: GenderEnum.MALE,
    name: 'EMPLOYEE.GENDER.MALE',
  },
  {
    code: GenderEnum.FEMALE,
    name: 'EMPLOYEE.GENDER.FEMALE',
  },
  {
    code: GenderEnum.OTHER,
    name: 'EMPLOYEE.GENDER.OTHER',
  },
];

export enum EmployeeSalesCoefficientType {
  PAYROLL = 'PAYROLL',
  SELECTION = 'SELECTION',
}
