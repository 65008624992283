import { FormControl, FormGroup, Validators } from '@angular/forms';
import { StatuteCodeEnum } from '../models';

type MinMaxRange = [min: number, max: number];

export const setBaseCoefficientsRules = (
  coefficientsForm: FormGroup,
  min: number,
  max: number
): void => {
  const { coefficient, coefficientBankHoliday } = coefficientsForm.controls;
  const coefficientValidators = [Validators.required, Validators.min(min), Validators.max(max)];

  coefficient.setValidators(coefficientValidators);
  coefficientBankHoliday.setValidators(coefficientValidators);
  coefficient.updateValueAndValidity();
  coefficientBankHoliday.updateValueAndValidity();
};

// For Full Admin only
export const FA_BASE_COEFFICIENT_MIN = 1;
export const FA_BASE_COEFFICIENT_MAX = 10;

// For non Full Admin roles
export const BASE_COEFFICIENTS_MIN_PER_STATUTE: Record<StatuteCodeEnum, number> = {
  [StatuteCodeEnum.LABOUR]: 1.7,
  [StatuteCodeEnum.LABOUR_STUDENT]: 1.12,
  [StatuteCodeEnum.WHITE_COLLAR]: 1.7,
  [StatuteCodeEnum.WHITE_COLLAR_STUDENT]: 1.18,
  [StatuteCodeEnum.FLEX_LABOUR]: 1.39,
  [StatuteCodeEnum.FLEX_WHITE_COLLAR]: 1.36,
  [StatuteCodeEnum.EXTRA]: 1.39,
  [StatuteCodeEnum.SEASONAL]: 1.55,
  [StatuteCodeEnum.LABOUR_STUDENT_WORKER]: 1.7,
  [StatuteCodeEnum.WHITE_COLLAR_STUDENT_WORKER]: 1.65,
};
export const BASE_COEFFICIENTS_MAX = 10;

// Secondary coefficients config (for high user roles)
const SECONDARY_COEFFICIENTS_RANGE: Record<string, MinMaxRange> = {
  coefficientTravelAllowance: [0, 5],
  coefficientMealVouchers: [0, 5],
  coefficientEcoVouchers: [0, 5],
};

export const DIMONA_COST_RANGE: MinMaxRange = [0, 0.4965];

export const setSecondaryCoefficientsRules = (coefficientsForm: FormGroup): void => {
  Object.entries(SECONDARY_COEFFICIENTS_RANGE).forEach(([coefficientFieldName, [min, max]]) => {
    const coefficientControl = coefficientsForm.controls[coefficientFieldName];
    const controlValidators = [Validators.required, Validators.min(min), Validators.max(max)];

    coefficientControl.setValidators(controlValidators);
    coefficientControl.updateValueAndValidity();
  });
};

export const isControlCoefficientOutOfLimits = (coefficientControl: FormControl): boolean => {
  return coefficientControl.hasError('min') || coefficientControl.hasError('max');
};

export const DEFAULT_EMPLOYEE_HOURS_PER_WEEK = 38;
export const DEFAULT_COMPANY_HOURS_PER_WEEK = 38;
