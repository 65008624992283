import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ScrollIntoViewModule } from '@falcon/shared/directives/scroll-into-view';

import { TimePickerComponent } from './time-picker.component';

@NgModule({
  imports: [CommonModule, ScrollIntoViewModule],
  exports: [TimePickerComponent],
  declarations: [TimePickerComponent],
})
export class TimePickerModule {}
