import {
  ContractDetailListModel,
  ModernScheduleItemModel,
  SingleDayTimetableModel,
} from './contract.model';
import { DictionaryItem } from './dictionary.model';

export interface ConfirmationModel {
  id: string;
  contractId: string;
  status: ConfirmationStatusEnum;
  date: string;
  timetable: SingleDayTimetableModel | null;
  notWorkedReason: DictionaryItem | null;
  notWorkedExtraInfo: string | null;
}

export enum ConfirmationStatusEnum {
  CONFIRMED = 'CONFIRMED',
  NOT_CONFIRMED = 'NOT_CONFIRMED',
  NOT_WORKED = 'NOT_WORKED',
}

export interface ContractConfirmationsModel
  extends Pick<ContractDetailListModel, 'id' | 'employee'> {
  confirmations: ConfirmationModel[];
  schedule: ModernScheduleItemModel[];
}
