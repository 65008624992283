import { Component, ChangeDetectionStrategy, ViewEncapsulation } from '@angular/core';
import { MatSnackBarRef } from '@angular/material/snack-bar';

@Component({
  selector: 'falcon-form-save-action',
  templateUrl: './form-save-action.component.html',
  styleUrls: ['./form-save-action.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class FormSaveActionComponent {
  constructor(public matSnackBarRef: MatSnackBarRef<FormSaveActionComponent>) {}

  save(): void {
    this.matSnackBarRef.dismissWithAction();
  }
}
