import { Injectable } from '@angular/core';

import { GACustomEventName } from './custom-events';

@Injectable({
  providedIn: 'root',
})
export class GoogleAnalyticsService {
  fireEvent(eventName: GACustomEventName, data?: any): void {
    (window as any).dataLayer.push({
      event: eventName,
      data,
    });
  }
}
