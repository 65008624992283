import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

const SSN_REGEXP = /^[0-9]{2}\.[0-9]{2}\.[0-9]{2}-[0-9]{3}.[0-9]{2}$/;
const SSN_UNDELIMITED_REGEXP = /^[0-9]{11}$/;
export const SSN_ERROR_NAME = 'ssnInvalid';

export const ssnValidator = (): ValidatorFn => {
  return (control: AbstractControl): ValidationErrors | null => {
    const ssn: string = control.value;
    if (!ssn) return null;

    const error = { [SSN_ERROR_NAME]: true };
    const ssnRegexpValid = SSN_REGEXP.test(ssn);
    const ssnUndelimitedRegexpValid = SSN_UNDELIMITED_REGEXP.test(ssn);

    let ssnValid = ssn && (ssnRegexpValid || ssnUndelimitedRegexpValid);

    if (ssnValid) {
      const dayStringFrom = ssnRegexpValid ? 6 : 4;
      const monthStringFrom = ssnRegexpValid ? 3 : 2;

      const dayString = ssn.slice(dayStringFrom, dayStringFrom + 2);
      const monthString = ssn.slice(monthStringFrom, monthStringFrom + 2);
      const yearString = ssn.slice(0, 2);

      const day = parseInt(dayString, 10);
      const month = parseInt(monthString, 10);

      const dayValid = 0 <= day && day <= 31;
      const monthValid =
        (0 <= month && month <= 12) ||
        (1 + 20 <= month && month <= 12 + 20) ||
        (1 + 40 <= month && month <= 12 + 40);

      const startFromValue = ssnRegexpValid ? 9 : 6;
      const value: number = parseInt(
        (parseInt(ssn[0] + ssn[1], 10) > 19 ? '' : '2') +
          yearString +
          monthString +
          dayString +
          ssn.slice(startFromValue, startFromValue + 3),
        10
      );

      const startFromChecksum = ssnRegexpValid ? 13 : 9;
      const checksum: number = parseInt(ssn.slice(startFromChecksum, startFromChecksum + 2), 10);

      ssnValid = dayValid && monthValid && 97 - (value % 97) === checksum;
    }

    return !ssnValid ? error : null;
  };
};
