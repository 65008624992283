import { DictionaryItem } from './dictionary.model';
import { CompanyFunctionModel } from './company-function.model';
import { AddressModel } from './address.model';
import { ConsultantModel } from './consultant.model';

export interface CompanyModel {
  bus: string;
  city: string;
  email: string;
  name: string;
  phoneNumber: string;
  postalCode: string;
  status: string;
  street: string;
  streetNumber: string;
  uuid: string;
  vat: string;
  vatCountryCode: string;
}

export interface CompanyDetailModel {
  id: string;
  name: string;
  nickName: string;
  functions: CompanyFunctionModel[];
  vat: string;
  vatCountryCode: string;
  officeCode: string;
  paritairComites: DictionaryItem[];
  personalContacts: PersonalContactModel[];
  status: CompanyStatusEnum;
  blockingReason: DictionaryItem<CompanyBlockingReasonEnum> | null;
  blockedBy: ConsultantModel | null;
  blockedOn: string | null;
  blockingExtraInfo: string | null;
  address: AddressModel;
  communication: CompanyCommunicationModel;
  hasVca: boolean | null;
  defaultRevenueOfficeCode: string | null;
  defaultRevenueConsultant: ConsultantModel | null;
  coefficientsPerStatute: PayrollCoefficientsModel | null;
  coefficientsGeneral: GeneralCoefficientsModel | null;
  presumedStartDate: string | null;
}

export enum CompanyStatusEnum {
  ACTIVE = 'ACTIVE',
  PROCESSING = 'PROCESSING',
  BLOCKED = 'BLOCKED',
}

export enum CompanyBlockingReasonEnum {
  BANKRUPTCY = 'BANKRUPTCY',
  BAD_PAYER = 'BAD_PAYER',
  NO_COOPERATION_ANYMORE = 'NO_COOPERATION_ANYMORE',
  NOT_CREDITWORTHY = 'NOT_CREDITWORTHY',
  WCO = 'WCO',
  AUTOMATIC_BLOCKING = 'AUTOMATIC_BLOCKING',
  SAFETY = 'SAFETY',
  PRINTED_MANDATE = 'PRINTED_MANDATE',
  FINANCE = 'FINANCE',
}

export interface PersonalContactModel {
  email: string | null;
  fullName: string;
  phoneNumber: string | null;
  position: string;
}

export interface CompanyCommunicationModel {
  email: string | null;
  phoneNumber: string | null;
  invoicePhoneNumber: string | null;
  language: DictionaryItem;
  einvoicesEmails: string[];
  eremindersEmails: string[];
  selfServiceEmails: string[];
}

export enum CompanyNicheEnum {
  PAYROLL_SERVICES = 'PAYROLL_SERVICES',
  JFX_CONSTRUCT = 'JFX_CONSTRUCT',
  JFX_INDUSTRY = 'JFX_INDUSTRY',
  JFX_TECHNICAL = 'JFX_TECHNICAL',
  WMR = 'WMR',
}

export interface PayrollCoefficientsModel {
  coefficientWhiteCollar: number | null;
  coefficientBlueCollar: number | null;
  coefficientWhiteCollarJobStudent: number | null;
  coefficientBlueCollarJobStudent: number | null;
  coefficientFlextimeWhiteCollar: number | null;
  coefficientFlextimeBlueCollar: number | null;
  coefficientWhiteCollarStudentWorker: number | null;
  coefficientBlueCollarStudentWorker: number | null;
  coefficientExtra: number | null;
  coefficientSeasonalWorker: number | null;
}

export interface GeneralCoefficientsModel {
  coefficientTravelAllowance: number | null;
  dimonaCost: number | null;
  coefficientMealVouchers: number | null;
  coefficientEcoVouchers: number | null;
  defaultTaxRate: string | null;
}

export interface CoefficientsConfig {
  coefficientsPerStatuteDefaultValues: PayrollCoefficientsModel;
  coefficientsGeneralDefaultValues: GeneralCoefficientsModel;
  coefficientsPerStatuteMinValues: PayrollCoefficientsModel;
}
