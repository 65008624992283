import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';

import { ScrollingModule } from '@angular/cdk/scrolling';
import { CloseScrollStrategy, Overlay } from '@angular/cdk/overlay';
import { MatTabsModule } from '@angular/material/tabs';
import { MatButtonModule } from '@angular/material/button';
import {
  MatBottomSheetConfig,
  MatBottomSheetModule,
  MAT_BOTTOM_SHEET_DEFAULT_OPTIONS,
} from '@angular/material/bottom-sheet';
import {
  MAT_BUTTON_TOGGLE_DEFAULT_OPTIONS,
  MatButtonToggleDefaultOptions,
  MatButtonToggleModule,
} from '@angular/material/button-toggle';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatDividerModule } from '@angular/material/divider';
import {
  MatDialogConfig,
  MatDialogModule,
  MAT_DIALOG_DEFAULT_OPTIONS,
} from '@angular/material/dialog';
import { MatSelectModule } from '@angular/material/select';
import {
  MatFormFieldDefaultOptions,
  MatFormFieldModule,
  MAT_FORM_FIELD_DEFAULT_OPTIONS,
} from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule, MAT_TOOLTIP_SCROLL_STRATEGY } from '@angular/material/tooltip';
import {
  MatSnackBarConfig,
  MatSnackBarModule,
  MAT_SNACK_BAR_DEFAULT_OPTIONS,
} from '@angular/material/snack-bar';
import {
  MatProgressSpinnerDefaultOptions,
  MatProgressSpinnerModule,
  MAT_PROGRESS_SPINNER_DEFAULT_OPTIONS,
} from '@angular/material/progress-spinner';

import {
  ContactEmployeeActionsModule,
  SingleDatePickerModule,
  DateRangePickerModule,
  PCCodeSelectModule,
  AddressInputModule,
  PhoneInputModule,
  DictionaryItemAutocompleteModule,
  TimePickerModule,
  FunctionPositionFieldModule,
  NavTabsComponent,
  CopyButtonComponent,
  WeekPaginatorComponent,
} from './components';

import { DecimalInputModule, ScrollIntoViewModule } from './directives';

import { LocalizedDateModule } from './pipes';
import { IonicModule } from '@ionic/angular';

const MAIN_MODULES = [CommonModule, TranslateModule, FormsModule, ReactiveFormsModule, IonicModule];

const MATERIAL_MODULES = [
  MatTabsModule,
  MatButtonModule,
  MatBottomSheetModule,
  MatButtonToggleModule,
  MatDatepickerModule,
  MatExpansionModule,
  MatDividerModule,
  MatDialogModule,
  MatSelectModule,
  MatFormFieldModule,
  MatInputModule,
  MatSnackBarModule,
  ScrollingModule,
  MatTooltipModule,
  MatProgressSpinnerModule,
];

const SHARED_COMPONENTS = [
  ContactEmployeeActionsModule,
  SingleDatePickerModule,
  DateRangePickerModule,
  PCCodeSelectModule,
  AddressInputModule,
  PhoneInputModule,
  DictionaryItemAutocompleteModule,
  TimePickerModule,
  FunctionPositionFieldModule,
];

const SHARED_STANDALONE_COMPONENTS = [
  NavTabsComponent,
  CopyButtonComponent,
  WeekPaginatorComponent,
];

const SHARED_DIRECTIVES = [DecimalInputModule, ScrollIntoViewModule];

const SHARED_PIPES = [LocalizedDateModule];

const SNACKBAR_DEFAULT_CONFIG: MatSnackBarConfig = {
  horizontalPosition: 'center',
  verticalPosition: 'top',
};

const BOTTOM_SHEET_DEFAULT_CONFIG: MatBottomSheetConfig = {
  autoFocus: false,
  closeOnNavigation: true,
  restoreFocus: false,
};

const PROGRESS_SPINNER_DEFAULT_CONFIG: MatProgressSpinnerDefaultOptions = {
  diameter: 60,
  strokeWidth: 3,
};

const DIALOG_DEFAULT_CONFIG: MatDialogConfig = {
  closeOnNavigation: true,
  hasBackdrop: true,
  autoFocus: false,
  restoreFocus: false,
  width: '90vw',
  maxWidth: '425px',
};

const FORM_FIELD_DEFAULT_CONFIG: MatFormFieldDefaultOptions = {
  appearance: 'outline',
  subscriptSizing: 'dynamic',
};

const MAT_BUTTON_TOGGLE_DEFAULT_CONFIG: MatButtonToggleDefaultOptions = {
  hideSingleSelectionIndicator: true,
  hideMultipleSelectionIndicator: true,
};

function tooltipScrollFactory(overlay: Overlay): () => CloseScrollStrategy {
  return () => overlay.scrollStrategies.close();
}

@NgModule({
  imports: [...SHARED_STANDALONE_COMPONENTS, NgxMaskDirective, NgxMaskPipe],
  exports: [
    ...MAIN_MODULES,
    ...MATERIAL_MODULES,
    ...SHARED_COMPONENTS,
    ...SHARED_DIRECTIVES,
    ...SHARED_PIPES,
    ...SHARED_STANDALONE_COMPONENTS,
    NgxMaskDirective,
    NgxMaskPipe,
  ],
  providers: [
    { provide: MAT_BUTTON_TOGGLE_DEFAULT_OPTIONS, useValue: MAT_BUTTON_TOGGLE_DEFAULT_CONFIG },
    { provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: SNACKBAR_DEFAULT_CONFIG },
    { provide: MAT_BOTTOM_SHEET_DEFAULT_OPTIONS, useValue: BOTTOM_SHEET_DEFAULT_CONFIG },
    { provide: MAT_PROGRESS_SPINNER_DEFAULT_OPTIONS, useValue: PROGRESS_SPINNER_DEFAULT_CONFIG },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: FORM_FIELD_DEFAULT_CONFIG,
    },
    { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: DIALOG_DEFAULT_CONFIG },
    {
      provide: MAT_TOOLTIP_SCROLL_STRATEGY,
      useFactory: tooltipScrollFactory,
      deps: [Overlay],
    },
    DatePipe,
    provideNgxMask(),
  ],
})
export class SharedModule {}
