import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '@falcon/env';
import { ConsultantModel } from '@falcon/shared/models';
import { BaseApi } from '@falcon/app/core/api/models';
import { ConsultantsRequestParams } from './consultants-request-params.model';

@Injectable({
  providedIn: 'root',
})
export class ConsultantsService extends BaseApi {
  private readonly CONSULTANTS_API_URL = `${environment.apiURL}/consultants`;

  constructor(private http: HttpClient) {
    super();
  }

  getConsultants(params?: ConsultantsRequestParams): Observable<ConsultantModel[]> {
    return this.http.get<ConsultantModel[]>(this.CONSULTANTS_API_URL, {
      params: params ? this.mapParamsToString(params) : undefined,
    });
  }
}
