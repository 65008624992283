import { CommonModule } from '@angular/common';
import { Component, ChangeDetectionStrategy, Input, Attribute, OnInit } from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { MatTabsModule } from '@angular/material/tabs';
import { MatBadgeModule } from '@angular/material/badge';
import { NavigationEnd, Router, RouterModule } from '@angular/router';
import { NavTab } from '@falcon/shared/models';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateModule } from '@ngx-translate/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, map, shareReplay, startWith, switchMap } from 'rxjs/operators';

@UntilDestroy()
@Component({
  standalone: true,
  selector: 'falcon-nav-tabs',
  templateUrl: './nav-tabs.component.html',
  styleUrls: ['./nav-tabs.component.scss'],
  imports: [CommonModule, RouterModule, TranslateModule, MatTabsModule, MatBadgeModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavTabsComponent implements OnInit {
  private readonly updateTabs$ = new BehaviorSubject<void>(undefined);

  @Input() tabs!: NavTab[];

  tabs$!: Observable<NavTab[]>;
  currUrl$: Observable<string> = this.router.events.pipe(
    filter(event => event instanceof NavigationEnd),
    map(event => (event as NavigationEnd).urlAfterRedirects),
    startWith(this.router.url),
    shareReplay(1),
    untilDestroyed(this)
  );

  constructor(private router: Router, @Attribute('color') public color: ThemePalette = 'primary') {}

  ngOnInit(): void {
    this.tabs$ = this.updateTabs$.pipe(
      switchMap(() => this.currUrl$),
      map(currURL =>
        this.tabs
          .map(tab => ({ ...tab, isActive: currURL.includes(tab.path) }))
          .sort((a: NavTab, b: NavTab) => (a.index ?? 0) - (b.index ?? 0))
      ),
      untilDestroyed(this)
    );
  }

  trackByTabPath(index: number, tab: NavTab) {
    return tab.path;
  }

  updateTabs(): void {
    this.updateTabs$.next();
  }
}
