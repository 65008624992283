export enum ContractRoutePath {
  CREATE = 'create',
  CONSULT = 'consult',
  EDIT = 'edit',
  CANCEL = 'cancel',
}

export enum ContractRoutePathParam {
  CONTRACT_ID = 'contractId',
}
