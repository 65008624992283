import { CommonModule } from '@angular/common';
import { Component, ChangeDetectionStrategy, Inject } from '@angular/core';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { MatButtonModule } from '@angular/material/button';
import { MatListModule } from '@angular/material/list';
import { TranslateModule } from '@ngx-translate/core';

import { WT_ADMIN_EMAIL, WT_SUPPORT_PHONE_NUMBER } from '@falcon/shared/constants';
import { PhoneNumberFormat, PhoneNumberUtil } from 'google-libphonenumber';

@Component({
  selector: 'falcon-contact-admin-actions',
  templateUrl: './contact-admin-actions.component.html',
  styleUrls: ['./contact-admin-actions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, TranslateModule, MatButtonModule, MatListModule],
})
export class ContactAdminActionsComponent {
  private readonly phoneNumberUtil = PhoneNumberUtil.getInstance();

  readonly adminPhoneNumber: string = this.phoneNumberUtil.format(
    WT_SUPPORT_PHONE_NUMBER,
    PhoneNumberFormat.NATIONAL
  );
  readonly adminMobilePhoneHref: string = `tel:${this.phoneNumberUtil.format(
    WT_SUPPORT_PHONE_NUMBER,
    PhoneNumberFormat.INTERNATIONAL
  )}`;
  readonly adminEmailHref: string = `mailto:${WT_ADMIN_EMAIL}`;

  constructor(
    public contactAdminActionsRef: MatBottomSheetRef<ContactAdminActionsComponent>,
    @Inject(MAT_BOTTOM_SHEET_DATA) public headingTranslation?: string
  ) {}
}
