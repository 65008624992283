import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule, SwRegistrationOptions } from '@angular/service-worker';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { CoreModule, RollbarErrorHandler, RollbarService, rollbarFactory } from '@falcon/core';
import { SnackBarModule } from '@falcon/shared/services/snack-bar';
import { featureFlagFactoryInit, FeatureFlagService } from '@falcon/app/shared/services';
import { PlatformDetector } from '@falcon/shared/utils';
import { AppRoutingModule } from './app.routing';
import { AppComponent } from './app.component';

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    ServiceWorkerModule.register('ngsw-worker.js'),
    IonicModule.forRoot(),
    CoreModule,
    SnackBarModule,
    AppRoutingModule,
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: featureFlagFactoryInit,
      multi: true,
      deps: [FeatureFlagService],
    },
    {
      provide: SwRegistrationOptions,
      useFactory: (): SwRegistrationOptions => ({
        enabled: !PlatformDetector.isNative && window.location.port !== '1443',
      }),
    },
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: ErrorHandler, useClass: RollbarErrorHandler },
    { provide: RollbarService, useFactory: rollbarFactory },
  ],
  declarations: [AppComponent],
  bootstrap: [AppComponent],
})
export class AppModule {}
