import { BehaviorSubject, Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';

export class Store<T> {
  protected state: T = {} as T;
  protected store: BehaviorSubject<T>;
  protected store$: Observable<T>;

  constructor(protected data: T) {
    this.state = {
      ...this.state,
      ...data,
    };
    this.store = new BehaviorSubject(data);
    this.store$ = this.store.asObservable().pipe(shareReplay(1));
  }

  get(): T {
    return this.data;
  }

  select<U>(func: (data: T) => U): U {
    return func(this.get());
  }

  get$(): Observable<T> {
    return this.store$;
  }

  select$<U>(func: (data: T) => U): Observable<U> {
    return this.get$().pipe(map(func));
  }

  update(data: Partial<T>): void {
    this.data = {
      ...this.data,
      ...data,
    };
    this.store.next(this.data);
  }

  clean(): void {
    this.data = {
      ...this.data,
      ...this.state,
    };
    this.store.next(this.data);
  }
}
