import { inject } from '@angular/core';
import { CanMatchFn, Router } from '@angular/router';
import { map } from 'rxjs/operators';

import { RootStore } from '@falcon/core/storage';
import { AppRoutePath } from '@falcon/app/app.routing.model';
import { MandateStatusEnum } from '@falcon/shared/models';

export const contractCreationGuard: CanMatchFn = () => {
  const rootStore = inject(RootStore);
  const router = inject(Router);

  return rootStore
    .getMandate$()
    .pipe(
      map(
        mandate =>
          !!mandate &&
          [MandateStatusEnum.NOT_REQUIRED, MandateStatusEnum.CLOSED].includes(mandate.status)
      ) || router.createUrlTree([AppRoutePath.SEARCH])
    );
};
