import { CommonModule } from '@angular/common';
import { Component, ChangeDetectionStrategy, Inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { SuccessDialogDataModel } from './success-dialog-data.model';

@Component({
  standalone: true,
  selector: 'falcon-success-dialog',
  templateUrl: './success-dialog.component.html',
  styleUrls: ['./success-dialog.component.scss'],
  imports: [CommonModule, MatButtonModule, MatDialogModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SuccessDialogComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: SuccessDialogDataModel) {}
}
