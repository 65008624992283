import { NgModule, ModuleWithProviders } from '@angular/core';
import { Router } from '@angular/router';

import {
  NavigationService,
  NAVIGATION_CONFIG_TOKEN,
  NavigationConfig,
  NavigationOption,
} from './navigation.service';
import { NavigationServiceFactory } from './navigation.factory';

@NgModule()
export class NavigationModule {
  static init<T extends string, R extends NavigationOption<T>>(
    NAVIGATION_CONFIG: NavigationConfig<T, R>
  ): ModuleWithProviders<NavigationModule> {
    return {
      ngModule: NavigationModule,
      providers: [
        {
          provide: NAVIGATION_CONFIG_TOKEN,
          useValue: NAVIGATION_CONFIG,
        },
        {
          provide: NavigationService,
          useFactory: NavigationServiceFactory,
          deps: [Router, NAVIGATION_CONFIG_TOKEN],
        },
      ],
    };
  }
}
