import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '@falcon/env';
import { DictionaryItem, DictionaryType } from '@falcon/shared/models';
import { BaseApi } from '@falcon/app/core/api/models';

@Injectable({
  providedIn: 'root',
})
export class DictionaryService extends BaseApi {
  constructor(private http: HttpClient) {
    super();
  }

  getDictionaryItems<T = string>(dictionaryType: DictionaryType): Observable<DictionaryItem<T>[]> {
    return this.http.get<DictionaryItem<T>[]>(`${environment.apiURL}/${dictionaryType}`);
  }

  getPublicDictionaryItems<T = string>(
    dictionaryType: DictionaryType
  ): Observable<DictionaryItem<T>[]> {
    return this.http.get<DictionaryItem<T>[]>(`${environment.publicApiURL}/${dictionaryType}`);
  }

  getTaxLevels<T = string>(isPublic = false, isFrontier = true): Observable<DictionaryItem<T>[]> {
    const api = isPublic ? environment.publicApiURL : environment.apiURL;
    return this.http.get<DictionaryItem<T>[]>(`${api}/taxLevels?isFrontier=${isFrontier}`);
  }

  getCollarTypes<T = string>(isPublic = false, pcCode?: string): Observable<DictionaryItem<T>[]> {
    const api = isPublic ? environment.publicApiURL : environment.apiURL;
    return this.http.get<DictionaryItem<T>[]>(`${api}/collartypes`, {
      params: this.mapParamsToString({ pcs: pcCode }),
    });
  }
}
