import { NgModule } from '@angular/core';

import { ContractComponent } from './contract.component';
import { ContractRoutingModule } from './contract.routing';

@NgModule({
  declarations: [ContractComponent],
  imports: [ContractRoutingModule],
})
export class ContractModule {}
