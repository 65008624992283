export interface MandateModel {
  id: string;
  companyId: string;
  subscriptionUrl: string;
  status: MandateStatusEnum;
}

export enum MandateStatusEnum {
  OPEN = 'OPEN',
  CLOSED = 'CLOSED',
  REQUIRED = 'REQUIRED',
  NOT_REQUIRED = 'NOT_REQUIRED',
  PENDING = 'PENDING',
}
