import { ActionPerformed, PushNotificationSchema } from '@capacitor/push-notifications';

export interface PushSubscriptionModel {
  id: string;
  userId: string;
  companyId: string;
  deviceToken: string;
}

export enum PushNotificationTypeEnum {
  FLASH_CREATED = 'FLASH_CREATED',
  APPLY_FLASH = 'APPLY_FLASH',
  ACCEPT_FLASH = 'ACCEPT_FLASH',
  CONTRACT_CANCELLED = 'CONTRACT_CANCELLED',
  CONTRACT_CREATED = 'CONTRACT_CREATED',
}

// Custom interfaces for better data typings

export interface PushNotification extends PushNotificationSchema {
  data: {
    notificationType: PushNotificationTypeEnum;
    // Fields below are supplied only per specific notification type.
    flashId: string;
    contractId: string;
  };
}

export interface PushNotificationAction extends ActionPerformed {
  notification: PushNotification;
}
