import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { MatListModule } from '@angular/material/list';
import { MatButtonModule } from '@angular/material/button';

import { ContactEmployeeActionsComponent } from './contact-employee-actions.component';
import { PhoneModule } from '@falcon/shared/services/phone';

@NgModule({
  imports: [CommonModule, TranslateModule, MatButtonModule, MatListModule, PhoneModule],
  exports: [ContactEmployeeActionsComponent],
  declarations: [ContactEmployeeActionsComponent],
})
export class ContactEmployeeActionsModule {}
