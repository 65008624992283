export enum WeekdayEnum {
  MONDAY = 'MONDAY',
  TUESDAY = 'TUESDAY',
  WEDNESDAY = 'WEDNESDAY',
  THURSDAY = 'THURSDAY',
  FRIDAY = 'FRIDAY',
  SATURDAY = 'SATURDAY',
  SUNDAY = 'SUNDAY',
}

export const weekdayMap: Record<number, WeekdayEnum> = {
  1: WeekdayEnum.MONDAY,
  2: WeekdayEnum.TUESDAY,
  3: WeekdayEnum.WEDNESDAY,
  4: WeekdayEnum.THURSDAY,
  5: WeekdayEnum.FRIDAY,
  6: WeekdayEnum.SATURDAY,
  7: WeekdayEnum.SUNDAY,
};

export const weekdayNumberMap: Record<WeekdayEnum, number> = {
  [WeekdayEnum.MONDAY]: 1,
  [WeekdayEnum.TUESDAY]: 2,
  [WeekdayEnum.WEDNESDAY]: 3,
  [WeekdayEnum.THURSDAY]: 4,
  [WeekdayEnum.FRIDAY]: 5,
  [WeekdayEnum.SATURDAY]: 6,
  [WeekdayEnum.SUNDAY]: 7,
};
