import {
  AbstractControl,
  UntypedFormControl,
  FormGroupDirective,
  NgForm,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';

import { GenderEnum } from '../models';
import { SSNHelper } from '../utils';

export const GENDER_ERROR_NAME = 'genderInvalid';

export const genderValidator = (): ValidatorFn => {
  return (control: AbstractControl): ValidationErrors | null => {
    const ssn: string = control.get('socialSecurityNumber')?.value;
    const gender: GenderEnum = control.get('gender')?.value;

    if (!ssn || !gender || gender === GenderEnum.OTHER) return null;

    const error = { [GENDER_ERROR_NAME]: true };
    const ssnHelper = new SSNHelper(ssn);
    const isFemaleBySSN = ssnHelper.isFemale();

    return (isFemaleBySSN && gender === GenderEnum.FEMALE) ||
      (!isFemaleBySSN && gender === GenderEnum.MALE)
      ? null
      : error;
  };
};

export class GenderErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(
    control: UntypedFormControl | null,
    form: FormGroupDirective | NgForm | null
  ): boolean {
    return control?.invalid || control?.parent?.hasError(GENDER_ERROR_NAME) || false;
  }
}
