import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import { DecimalPipe } from '@angular/common';
import { UntypedFormControl } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { fromEvent } from 'rxjs';
import { distinctUntilChanged, filter, map, take } from 'rxjs/operators';

@UntilDestroy()
@Directive({
  selector: 'input[falconDecimalInput]',
})
export class DecimalInputDirective implements OnInit {
  @Input() formControl!: UntypedFormControl;
  @Input('falconDecimalInput') fractionDigits!: number;
  @Input() formatInitialValue = false;

  private numberFormat!: string;

  constructor(private inputRef: ElementRef<HTMLInputElement>, private decimalPipe: DecimalPipe) {}

  get inputElem(): HTMLInputElement {
    return this.inputRef.nativeElement;
  }

  ngOnInit(): void {
    this.numberFormat = `1.${this.fractionDigits}-${this.fractionDigits}`;
    this.inputElem.type = 'number';
    const step = 1 / Number(`1${'0'.repeat(this.fractionDigits)}`);
    this.inputElem.step = step.toString();
    this.formControl?.valueChanges
      .pipe(
        filter(() => this.formatInitialValue),
        take(1)
      )
      .subscribe((value: string) => this.setFormattedValue(value));
    fromEvent<FocusEvent>(this.inputElem, 'blur')
      .pipe(
        map(event => (event.target as HTMLInputElement).value),
        distinctUntilChanged(),
        filter(Boolean),
        untilDestroyed(this)
      )
      .subscribe(value => this.setFormattedValue(value as string));
  }

  private setFormattedValue(value: string): void {
    this.formControl.setValue(this.decimalPipe.transform(value, this.numberFormat));
  }
}
