import { DEFAULT_CURRENCY_CODE, NgModule } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { registerLocaleData } from '@angular/common';
import localeNl from '@angular/common/locales/nl';
import {
  MatMomentDateModule,
  MAT_MOMENT_DATE_FORMATS,
  MomentDateAdapter,
} from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import moment, { Moment } from 'moment';

import { HttpLoaderFactory } from './http-loader.factory';
import { FalconLanguages } from './language';

@NgModule({
  imports: [
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
    { provide: DEFAULT_CURRENCY_CODE, useValue: 'EUR' },
  ],
  exports: [TranslateModule, MatMomentDateModule],
})
export class I18nModule {
  private readonly falconLangKey = 'falconLang';

  constructor(
    private translateService: TranslateService,
    private dateAdapter: DateAdapter<Moment>
  ) {
    registerLocaleData(localeNl, FalconLanguages.NL);
    this.translateService.langs.push(...Object.values(FalconLanguages));
    this.translateService.setDefaultLang(
      localStorage.getItem(this.falconLangKey) || FalconLanguages.NL
    );
    this.translateService.use(this.translateService.defaultLang);
    this.translateService.onLangChange.subscribe(({ lang }) => {
      document.documentElement.lang = lang;
      localStorage.setItem(this.falconLangKey, lang);
      this.dateAdapter.setLocale(lang);
      moment.locale(lang);
    });
    moment.updateLocale(FalconLanguages.EN, {
      week: {
        dow: 1,
      },
    });
  }
}
