<header
  class="d-flex flex-column align-items-center position-relative border-bottom falcon-color-primary text-center py-2 px-5"
>
  <button
    *ngIf="linkFunctionStepper.selectedIndex"
    color="primary"
    mat-icon-button
    class="header-action-btn"
    (click)="linkFunctionStepper.previous()"
  >
    <i class="falcon-icon falcon-icon-arrow-left"></i>
  </button>

  <h1 class="mb-1">{{ 'LINK_FUNCTION.TITLE' | translate }}</h1>
  <span *ngIf="linkFunctionStepper.selectedIndex > 0">{{ functionControl.value?.name }}</span>
  <span *ngIf="linkFunctionStepper.selectedIndex > 1">{{ statuteControl.value?.name }}</span>

  <button mat-icon-button mat-dialog-close color="primary" class="header-action-btn stroked">
    <i class="falcon-icon falcon-icon-close"></i>
  </button>
</header>

<falcon-link-function-stepper class="flex-grow-1" [linear]="true" #linkFunctionStepper>
  <cdk-step
    [label]="'LINK_FUNCTION.SELECT_FUNCTION_LABEL' | translate"
    [stepControl]="functionControl"
  >
    <ng-container *ngIf="functions$ | async as functions; else loadingSpinner">
      <mat-button-toggle-group [vertical]="true" [formControl]="functionControl">
        <mat-button-toggle *ngFor="let function of functions" [value]="function">
          <div class="d-flex flex-column py-2">
            <span class="mb-2">{{ function.name }}</span>
            <span class="function-subinfo">
              {{ function.collar.name }} - {{ function.employmentAddress.city }}
            </span>
          </div>
        </mat-button-toggle>
      </mat-button-toggle-group>

      <div class="d-flex flex-column align-items-center mt-2">
        <span>{{ 'LINK_FUNCTION.NEED_MORE_FUNCTIONS' | translate }}</span>
        <a mat-button class="falcon-color-primary" (click)="openContactAdminActions()">
          <u>{{ 'GENERAL.CONTACT_US' | translate }}</u>
        </a>
      </div>
    </ng-container>
  </cdk-step>

  <cdk-step
    [label]="'LINK_FUNCTION.SELECT_STATUTE_LABEL' | translate"
    [stepControl]="statuteControl"
  >
    <ng-container *ngIf="statutes$ | async as statutes; else loadingSpinner">
      <mat-button-toggle-group class="flex-grow-1" [vertical]="true" [formControl]="statuteControl">
        <mat-button-toggle *ngFor="let statute of statutes" [value]="statute">
          {{ statute.name }}
        </mat-button-toggle>
      </mat-button-toggle-group>

      <div
        *ngIf="statuteControl.hasError('isSameFunctionTemplateUsed')"
        class="text-center falcon-color-error"
      >
        {{ 'LINK_FUNCTION.SAME_FUNCTION_TEMPLATE_USED_ERROR' | translate }}
      </div>
    </ng-container>
  </cdk-step>

  <cdk-step [label]="'LINK_FUNCTION.SELECT_WAGE_LABEL' | translate" [stepControl]="wageGroup">
    <ion-toggle [formControl]="wageGroup.controls.minWageEnabled">
      {{ 'LINK_FUNCTION.MIN_WAGE_TOGGLE_LABEL' | translate }}
    </ion-toggle>

    <mat-form-field class="mt-2" [hidden]="wageGroup.controls.minWageEnabled.value">
      <mat-label>{{ 'LINK_FUNCTION.MIN_WAGE_FIELD_LABEL' | translate }}</mat-label>
      <input matInput [falconDecimalInput]="4" [formControl]="wageGroup.controls.minWageAmount" />
      <span matTextSuffix>EUR</span>
      <mat-hint class="d-flex mt-1">
        <i class="falcon-icon falcon-icon-info_filled mr-1"></i>
        <span>
          {{ 'LINK_FUNCTION.MIN_WAGE_FIELD_HINT' | translate }}
        </span>
      </mat-hint>
      <mat-error *ngIf="wageGroup.controls.minWageAmount.hasError('required')">{{
        'LINK_FUNCTION.WAGE_REQUIRED_ERROR' | translate
      }}</mat-error>
      <mat-error *ngIf="wageGroup.controls.minWageAmount.getError('min') as wageMinError"
        >{{ 'LINK_FUNCTION.WAGE_INVALID_MIN_VALUE' | translate : { minValue: wageMinError.min } }}
      </mat-error>
    </mat-form-field>

    <mat-divider class="my-2"></mat-divider>

    <ion-toggle [formControl]="wageGroup.controls.mealVouchersEnabled">
      {{ 'LINK_FUNCTION.MEAL_VOUCHERS_TOGGLE_LABEL' | translate }}
    </ion-toggle>

    <mat-form-field class="mt-2" [hidden]="!wageGroup.controls.mealVouchersEnabled.value">
      <mat-label>{{ 'LINK_FUNCTION.MEAL_VOUCHERS_FIELD_LABEL' | translate }}</mat-label>
      <input
        matInput
        [falconDecimalInput]="4"
        [formControl]="wageGroup.controls.mealVouchersAmount"
      />
      <mat-error *ngIf="wageGroup.controls.mealVouchersAmount.hasError('required')">{{
        'LINK_FUNCTION.MEAL_VOUCHERS_REQUIRED_ERROR' | translate
      }}</mat-error>
      <mat-error
        *ngIf="wageGroup.controls.mealVouchersAmount.getError('min') as mealVouchersMinError"
        >{{
          'LINK_FUNCTION.MEAL_VOUCHERS_INVALID_MIN_VALUE'
            | translate : { minValue: mealVouchersMinError.min }
        }}
      </mat-error>
      <mat-error
        *ngIf="wageGroup.controls.mealVouchersAmount.getError('max') as mealVouchersMaxError"
        >{{
          'LINK_FUNCTION.MEAL_VOUCHERS_INVALID_MAX_VALUE'
            | translate : { maxValue: mealVouchersMaxError.max }
        }}
      </mat-error>
      <span matTextSuffix>EUR</span>
    </mat-form-field>
  </cdk-step>
</falcon-link-function-stepper>

<div class="d-flex flex-column p-3">
  <button
    color="accent"
    type="button"
    class="falcon-rounded-btn w-100"
    mat-flat-button
    (click)="nextStep()"
    [disabled]="linkFunctionStepper.selected?.stepControl?.invalid"
  >
    {{ (isLastStep ? 'LINK_FUNCTION.SUBMIT_BUTTON' : 'GENERAL.NEXT') | translate }}
  </button>
</div>

<ng-template #loadingSpinner>
  <mat-spinner class="container-absolute-spinner"></mat-spinner>
</ng-template>
