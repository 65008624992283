import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { AddressModel } from '@falcon/shared/models';
export const ADDRESS_ERROR_NAME = 'addressInvalid';

export const addressValidator = (): ValidatorFn => {
  return (control: AbstractControl): ValidationErrors | null => {
    const error = { [ADDRESS_ERROR_NAME]: true };
    const address: AddressModel = control.value;

    if (!address) return null;

    const { street, streetNumber, bus, postalCode, city, country } = address;
    
    return (street && streetNumber && postalCode && city && country) ? null : error;
  };
};
