import { ChangeDetectionStrategy, Component, HostBinding, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MatDialogModule, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';

import { GenericConfirmDialog } from './generic-confirm-dialog.model';

@Component({
  selector: 'falcon-generic-confirm-dialog',
  standalone: true,
  imports: [CommonModule, MatDialogModule, MatButtonModule],
  templateUrl: './generic-confirm-dialog.component.html',
  styleUrls: ['./generic-confirm-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GenericConfirmDialogComponent {
  @HostBinding('class') hostClasses = ['d-flex', 'flex-column'];

  constructor(@Inject(MAT_DIALOG_DATA) public data?: GenericConfirmDialog) {}
}
