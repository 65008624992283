import { DateTimeFormats } from '@falcon/shared/enums';
import moment, { Moment } from 'moment';

export class SSNHelper {
  private readonly SSN_BIRTHDAY_DATE_START_INDEX = 0;
  private readonly SSN_BIRTHDAY_DATE_LENGTH = 6;

  private readonly SSN_GENDER_NUMBER_START_INDEX = 6;
  private readonly SSN_GENDER_NUMBER_LENGTH = 3;

  private readonly NON_DIGIT_REGEXP = /\D/g;

  private rawSSN: string;

  constructor(ssn: string) {
    this.rawSSN = ssn.replace(this.NON_DIGIT_REGEXP, '');
  }

  public isFemale(): boolean {
    return this.isEven(this.getGenderNumber());
  }

  public getBirthdayDate(): Moment {
    const ssnBirthdayPart = this.rawSSN.slice(
      this.SSN_BIRTHDAY_DATE_START_INDEX,
      this.SSN_BIRTHDAY_DATE_START_INDEX + this.SSN_BIRTHDAY_DATE_LENGTH
    );
    const [year2Digit, month, day] = this.getEveryNthCharacters(ssnBirthdayPart, 2);
    const year = this.isBornIn20Century() ? `19${year2Digit}` : `20${year2Digit}`;
    return moment(`${year}-${month}-${day}`, DateTimeFormats.ISO_DATE);
  }

  private isBornIn20Century(): boolean {
    const [first9DigitsPart, checksumPart] = this.getEveryNthCharacters(this.rawSSN, 9);
    return 97 - (parseInt(first9DigitsPart) % 97) === parseInt(checksumPart);
  }

  private getGenderNumber(): number {
    const ssnGenderPart = this.rawSSN.slice(
      this.SSN_GENDER_NUMBER_START_INDEX,
      this.SSN_GENDER_NUMBER_START_INDEX + this.SSN_GENDER_NUMBER_LENGTH
    );
    return parseInt(ssnGenderPart);
  }

  private isEven(n: number): boolean {
    return n % 2 === 0;
  }

  private getEveryNthCharacters(str: string, charactersCount: number): string[] {
    return str.match(new RegExp(`.{1,${charactersCount}}`, 'g')) || [];
  }
}
