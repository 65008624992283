import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseApi } from '@falcon/core/api/models';
import {
  CancelFlashModel,
  FlashApplicationModel,
  FlashApplicationStatusEnum,
  FlashModel,
  PageableResponsePayloadModel,
} from '@falcon/shared/models';
import { environment } from '@falcon/env';
import { FlashesApplicationsRequestParamsModel } from './flashes-applications-request-params.model';
import {
  ApplicationsListRequestParamsModel,
  FlashesListRequestParamsModel,
} from './flashes-list-request-params.model';

@Injectable({
  providedIn: 'root',
})
export class FlashService extends BaseApi {
  private readonly FLASHES_API_URL = `${environment.apiURL}/companyflashes`;
  private readonly FLASHES_APPLICATIONS_API_URL = `${this.FLASHES_API_URL}/applications`;

  constructor(private http: HttpClient) {
    super();
  }

  getFlashes(params: FlashesListRequestParamsModel): Observable<FlashModel[]> {
    return this.http
      .get<PageableResponsePayloadModel<FlashModel[]>>(this.FLASHES_API_URL, {
        params: this.mapParamsToString(params),
      })
      .pipe(map(resp => resp.content));
  }

  getFlash(flashId: string): Observable<FlashModel> {
    return this.http.get<FlashModel>(`${this.FLASHES_API_URL}/${flashId}`);
  }

  getApplications(params: ApplicationsListRequestParamsModel): Observable<FlashApplicationModel[]> {
    return this.http
      .get<PageableResponsePayloadModel<FlashApplicationModel[]>>(
        this.FLASHES_APPLICATIONS_API_URL,
        {
          params: this.mapParamsToString(params),
        }
      )
      .pipe(map(resp => resp.content));
  }

  createFlash(flashData: FlashModel): Observable<FlashModel> {
    return this.http.post<FlashModel>(this.FLASHES_API_URL, flashData);
  }

  applyForFlash(flashApp: FlashApplicationModel): Observable<FlashApplicationModel> {
    return this.http.post<FlashApplicationModel>(this.FLASHES_APPLICATIONS_API_URL, flashApp);
  }

  getFlashesApplications(
    params: FlashesApplicationsRequestParamsModel
  ): Observable<FlashApplicationModel[]> {
    return this.http
      .get<PageableResponsePayloadModel<FlashApplicationModel[]>>(
        this.FLASHES_APPLICATIONS_API_URL,
        {
          params: this.mapParamsToString(params),
        }
      )
      .pipe(map(resp => resp.content));
  }

  changeFlashApplicationStatus(
    flashAppId: string,
    status: FlashApplicationStatusEnum
  ): Observable<void> {
    return this.http.patch<void>(`${this.FLASHES_APPLICATIONS_API_URL}/${flashAppId}`, {
      status,
    });
  }

  cancelFlash(cancelData: CancelFlashModel): Observable<CancelFlashModel> {
    return this.http.post<CancelFlashModel>(`${this.FLASHES_APPLICATIONS_API_URL}/employeeHideList`, cancelData);
  }
}
