import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatDatepickerModule } from '@angular/material/datepicker';

import { SingleDatePickerComponent } from './single-date-picker.component';

@NgModule({
  declarations: [SingleDatePickerComponent],
  imports: [CommonModule, MatDatepickerModule],
  exports: [SingleDatePickerComponent],
})
export class SingleDatePickerModule {}
