<button mat-icon-button (click)="switchMonth('prev')" [disabled]="!prevMonthBtnEnabled">
  <i class="falcon-icon falcon-icon-chevron-left"></i>
</button>

<span class="d-flex justify-content-center align-items-center flex-grow-1 font-weight-bold">
  {{ periodLabel }}
</span>

<button mat-icon-button (click)="switchMonth('next')" [disabled]="!nextMonthBtnEnabled">
  <i class="falcon-icon falcon-icon-chevron-right"></i>
</button>
