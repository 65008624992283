import { Component, ChangeDetectionStrategy, Inject } from '@angular/core';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { PhoneNumberFormat } from 'google-libphonenumber';

import { EmployeeModel } from '@falcon/shared/models';
import { PhoneService } from '@falcon/shared/services/phone';

@Component({
  selector: 'falcon-contact-employee-actions',
  templateUrl: './contact-employee-actions.component.html',
  styleUrls: ['./contact-employee-actions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContactEmployeeActionsComponent {
  private readonly employeePhoneNumber = this.phoneService.parseBelgiumNumber(
    this.employee.phoneMobileNumber
  );

  get formattedPhoneNumber() {
    return this.phoneService.format(this.employeePhoneNumber, PhoneNumberFormat.INTERNATIONAL);
  }

  get employeeMobilePhoneHref(): string {
    return `tel:${this.formattedPhoneNumber}`;
  }

  get employeeWhatsAppHref(): string {
    return `https://wa.me/${this.phoneService.format(
      this.employeePhoneNumber,
      PhoneNumberFormat.E164
    )}`;
  }

  constructor(
    @Inject(MAT_BOTTOM_SHEET_DATA) public employee: EmployeeModel,
    public contactEmployeeActionsRef: MatBottomSheetRef<ContactEmployeeActionsComponent>,
    private phoneService: PhoneService
  ) {}
}
