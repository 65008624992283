import {
  Component,
  ChangeDetectionStrategy,
  Input,
  OnInit,
  Output,
  EventEmitter,
} from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { filter, map, startWith } from 'rxjs/operators';

import { AddressModel } from '@falcon/shared/models';
import { DialogService } from '@falcon/shared/services/dialog';
import { AddressFormComponent } from './components/address-form/address-form.component';

export interface AddressFormDialogData {
  address: AddressModel | null;
  isFormDisabled: boolean;
}

@Component({
  selector: 'falcon-address-input',
  templateUrl: './address-input.component.html',
  styleUrls: ['./address-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddressInputComponent implements OnInit {
  formattedValue$!: Observable<string>;
  isRequiredControl = false;

  @Input() label!: string;
  @Input() control!: FormControl<AddressModel | null>;
  @Input() disabled!: boolean;
  @Output() addressChange = new EventEmitter<AddressModel>();

  constructor(private dialogService: DialogService) {}

  ngOnInit(): void {
    this.formattedValue$ = this.control.valueChanges.pipe(
      startWith(this.control.value),
      map(address => {
        if (!address) return '';

        const { street, streetNumber, bus, postalCode, city, country } = address;
        const firstPart = [street, streetNumber, bus].filter(Boolean).join(' ');
        const middlePart = [postalCode, city].filter(Boolean).join(' ');
        const lastPart = [country].filter(Boolean).join(' ');

        return [firstPart, middlePart, lastPart].filter(Boolean).join(', ');
      })
    );
    this.isRequiredControl = this.control.hasValidator(Validators.required);
  }

  onEditAddress(): void {
    this.dialogService.matDialog
      .open<AddressFormComponent, AddressFormDialogData>(AddressFormComponent, {
        data: {
          address: this.control.value,
          isFormDisabled: this.control.disabled,
        },
      })
      .afterClosed()
      .pipe(filter(Boolean))
      .subscribe(address => this.addressChange.emit(address as AddressModel));
  }
}
