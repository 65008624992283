import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { CdkStepper } from '@angular/cdk/stepper';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  combineLatest,
  distinctUntilChanged,
  filter,
  map,
  of,
  shareReplay,
  startWith,
  switchMap,
  tap,
} from 'rxjs';

import {
  CompanyFunctionDetailModel,
  FunctionStatuteModel,
  GenericStatueCodeEnum,
  StatuteCodeEnum,
  UserRolesEnum,
} from '@falcon/app/shared/models';
import { CompanyFunctionService, EmployeeWageService } from '@falcon/app/core/api';
import { ContactAdminActionsComponent } from '@falcon/app/shared/components';
import { LinkFunctionDialogData, LinkFunctionResponseModel } from './link-function.model';
import { ALLOWED_COMPANY_PC_CODES_FOR_FLASH, MIN_WAGE } from '@falcon/app/shared/constants';
import { AuthStore } from '@falcon/app/core/storage';

@UntilDestroy()
@Component({
  selector: 'falcon-link-function',
  templateUrl: './link-function.component.html',
  styleUrls: ['./link-function.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LinkFunctionComponent implements OnInit {
  readonly form = this.generateForm();
  readonly functions$ = this.companyFunctionService.getCompanyFunctions({
    companyId: this.data.companyId,
  });
  readonly statutes$ = this.functionControl.valueChanges.pipe(
    filter(Boolean),
    tap(() => this.statuteControl.reset()),
    switchMap(func => this.companyFunctionService.getFunctionStatutes(func.id)),
    map(this.filterStatutes.bind(this))
  );
  private readonly minimalWagesForFlashCompanies$ = this.employeeWageService
    .getMinEmployeeWages()
    .pipe(shareReplay(1));

  @HostBinding('class') hostClasses = ['d-flex', 'flex-column', 'falcon-bg-pre-white', 'h-100'];

  @ViewChild('linkFunctionStepper') linkFunctionStepper!: CdkStepper;

  get isLastStep(): boolean {
    return this.linkFunctionStepper?.selectedIndex + 1 === this.linkFunctionStepper?.steps.length;
  }

  get functionControl() {
    return this.form.controls.func;
  }

  get statuteControl() {
    return this.form.controls.statute;
  }

  get wageGroup() {
    return this.form.controls.wage;
  }

  get isSameFunctionTemplateSelected(): boolean {
    if (!this.data.existingFunctions?.length) return false;

    const selectedFunction = this.functionControl.value;
    const selectedStatute = this.statuteControl.value;

    if (!selectedFunction || !selectedStatute) return false;

    return this.data.existingFunctions.some(
      func =>
        func.companyFunctionId === selectedFunction.id && func.statuteCode === selectedStatute.code
    );
  }

  constructor(
    public dialogRef: MatDialogRef<LinkFunctionComponent, LinkFunctionResponseModel>,
    private companyFunctionService: CompanyFunctionService,
    private fb: FormBuilder,
    private bottomSheetService: MatBottomSheet,
    private authStore: AuthStore,
    private employeeWageService: EmployeeWageService,
    @Inject(MAT_DIALOG_DATA)
    public data: LinkFunctionDialogData
  ) {}

  ngOnInit(): void {
    combineLatest([
      this.functionControl.valueChanges.pipe(filter(Boolean)),
      this.statuteControl.valueChanges.pipe(filter(Boolean)),
    ])
      .pipe(
        switchMap(([selectedFunc, selectedStatute]) => {
          return ALLOWED_COMPANY_PC_CODES_FOR_FLASH.includes(selectedFunc.paritairComite.code)
            ? this.minimalWagesForFlashCompanies$.pipe(
                map(
                  minWages =>
                    minWages.find(
                      ({ minWageKey }) =>
                        minWageKey.paritairComite === selectedFunc.paritairComite.code &&
                        minWageKey.statute === selectedStatute.code
                    )?.wage || MIN_WAGE
                )
              )
            : of(MIN_WAGE);
        }),
        distinctUntilChanged(),
        untilDestroyed(this)
      )
      .subscribe(minWage => {
        const { minWageAmount } = this.wageGroup.controls;
        minWageAmount.setValidators([Validators.required, Validators.min(minWage)]);
        minWageAmount.updateValueAndValidity();
      });

    const { minWageEnabled, minWageAmount, mealVouchersEnabled, mealVouchersAmount } =
      this.wageGroup.controls;

    minWageEnabled.valueChanges
      .pipe(startWith(minWageEnabled.value), untilDestroyed(this))
      .subscribe(minWageEnabledValue => {
        if (minWageEnabledValue) {
          minWageAmount.disable();
          return;
        }

        minWageAmount.reset();
        minWageAmount.enable();
      });
    mealVouchersEnabled.valueChanges
      .pipe(startWith(mealVouchersEnabled.value), untilDestroyed(this))
      .subscribe(mealVouchersEnabledValue => {
        if (mealVouchersEnabledValue) {
          mealVouchersAmount.reset();
          mealVouchersAmount.enable();
          return;
        }

        mealVouchersAmount.disable();
      });
  }

  nextStep(): void {
    this.isLastStep
      ? this.dialogRef.close(this.form.getRawValue() as LinkFunctionResponseModel)
      : this.linkFunctionStepper.next();
  }

  openContactAdminActions(): void {
    this.bottomSheetService.open(ContactAdminActionsComponent);
  }

  private generateForm() {
    return this.fb.group({
      func: this.fb.control<CompanyFunctionDetailModel | null>(null, Validators.required),
      statute: this.fb.control<FunctionStatuteModel | null>(null, [
        Validators.required,
        this.sameFunctionTemplateUsedValidator(),
      ]),
      wage: this.fb.group({
        minWageEnabled: this.fb.nonNullable.control(true),
        minWageAmount: this.fb.control<number | null>(null, [
          Validators.required,
          Validators.min(MIN_WAGE),
        ]),
        mealVouchersEnabled: this.fb.nonNullable.control(false),
        mealVouchersAmount: this.fb.control<number | null>(null, [
          Validators.required,
          Validators.min(2.18),
          Validators.max(8),
        ]),
      }),
    });
  }

  private sameFunctionTemplateUsedValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control || !this.data.existingFunctions?.length) return null;

      const form = control.root as typeof this.form;
      const { func, statute } = form.getRawValue() || {};

      if (!func || !statute) return null;

      return this.data.existingFunctions.some(
        existingFunc =>
          existingFunc.companyFunctionId === func.id && existingFunc.statuteCode === statute.code
      )
        ? { isSameFunctionTemplateUsed: true }
        : null;
    };
  }

  private filterStatutes(statutes: FunctionStatuteModel[]): FunctionStatuteModel[] {
    return statutes.filter(({ code, genericStatute }) => {
      if (code === StatuteCodeEnum.SEASONAL) {
        return false;
      }

      if (
        genericStatute.code === GenericStatueCodeEnum.WORKER_STUDENT &&
        !this.authStore.hasRoles([
          UserRolesEnum.FULL_ADMIN,
          UserRolesEnum.SUPER_ADMIN,
          UserRolesEnum.SALES_ADMIN,
          UserRolesEnum.PAYROLL_ADMIN,
          UserRolesEnum.PAYROLL_MANAGEMENT,
          UserRolesEnum.CREDIT_CONTROLLER,
          UserRolesEnum.PREVENTION_ADVISOR,
        ])
      ) {
        return false;
      }

      return true;
    });
  }
}
