<header class="d-flex flex-column text-center border-bottom falcon-color-primary px-5 py-2">
  <button mat-icon-button mat-dialog-close class="header-action-btn falcon-color-black back-btn">
    <i class="falcon-icon falcon-icon-arrow-left"></i>
  </button>

  <h3 class="m-0 font-weight-bold">{{ flashApp.flash.companyFunction.name }}</h3>
  <span class="font-semibold">{{ flashFormattedDatetime }}</span>
</header>

<main class="d-flex flex-column flex-fill overflow-auto p-3">
  <span class="text-center falcon-color-primary font-semibold">
    {{ 'ACCEPT_EMPLOYEE_FOR_FLASH.SURE_TO_ACCEPT_EMPLOYEE' | translate : { employeeFullName } }}
  </span>

  <app-flash-candidate-card
    class="my-3"
    [application]="flashApp"
    [isActionButtonsShown]="false"
  ></app-flash-candidate-card>

  <div class="flex-grow-1">
    <mat-form-field class="my-3 w-100">
      <mat-label>{{ 'APPLY_FOR_FLASH.PRESENTING_FIELD_LABEL' | translate }}</mat-label>
      <textarea matInput rows="2" [value]="flashApp.employeeSummary" readonly></textarea>
    </mat-form-field>
  </div>

  <div class="d-flex flex-column falcon-color-gray-text info-block list-gap">
    <h5 class="text-center m-0 font-semibold">
      {{ 'ACCEPT_EMPLOYEE_FOR_FLASH.IMPORTANT_INFO_TITLE' | translate }}
    </h5>

    <span>{{ 'ACCEPT_EMPLOYEE_FOR_FLASH.IMPORTANT_INFO_1' | translate }}</span>
    <span>{{ 'ACCEPT_EMPLOYEE_FOR_FLASH.IMPORTANT_INFO_2' | translate }}</span>
  </div>
</main>

<div class="px-3 pb-3">
  <button
    mat-flat-button
    color="accent"
    class="falcon-rounded-btn w-100"
    [disabled]="isLoading$ | async"
    (click)="acceptEmployee()"
  >
    {{ 'ACCEPT_EMPLOYEE_FOR_FLASH.ACCEPT_CANDIDATE_BUTTON' | translate }}
  </button>
</div>
