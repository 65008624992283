import { CoefficientsConfig, CompanyDetailModel, MandateModel } from '@falcon/shared/models';
import { Subject } from 'rxjs';

export class RootState {
  company: CompanyDetailModel | null = null; // Currently selected company from the search list
  mandate: MandateModel | null = null;
  companiesContractListWeekStart: Record<string, string> = {};
  companiesConfirmationListWeekStart: Record<string, string> = {};
  recountGlobalUnconfirmedActualsTrigger = new Subject<void>();
  coefficientsConfig: CoefficientsConfig | null = null;
}
