import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse,
  HttpStatusCode,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { DialogService } from '@falcon/shared/services/dialog';
import { AuthStore } from '@falcon/core/storage';
import { UserRolesEnum } from '@falcon/shared/models';
import { ContractService } from '../api';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(
    private dialogService: DialogService,
    private authStore: AuthStore,
    private contractService: ContractService
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (
          error.status === HttpStatusCode.BadRequest &&
          request.url.includes(this.contractService.CONTRACTS_API_URL)
        ) {
          this.dialogService.openAdminErrorDialog(error.error);
        } else if (
          ![HttpStatusCode.Unauthorized, HttpStatusCode.GatewayTimeout].includes(error.status)
        ) {
          this.authStore.hasRoles([
            UserRolesEnum.FULL_ADMIN,
            UserRolesEnum.SUPER_ADMIN,
            UserRolesEnum.SALES_ADMIN,
            UserRolesEnum.PAYROLL_ADMIN,
            UserRolesEnum.PAYROLL_SALES,
            UserRolesEnum.PAYROLL_MANAGEMENT,
          ])
            ? this.dialogService.openAdminErrorDialog(error.error)
            : this.dialogService.openHttpErrorDialog();
        }

        return throwError(() => error);
      })
    );
  }
}
