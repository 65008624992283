import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@falcon/env';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { InvoiceModel, PageableResponsePayloadModel } from '@falcon/shared/models';
import { BaseApi } from '../models';
import { InvoicesRequestParamsModel } from './invoices-requests-params.model';

@Injectable({
  providedIn: 'root',
})
export class InvoiceService extends BaseApi {
  private readonly INVOICES_API_URL = `${environment.apiURL}/invoices`;

  constructor(private http: HttpClient) {
    super();
  }

  getInvoices(params: InvoicesRequestParamsModel): Observable<InvoiceModel[]> {
    return this.http
      .get<PageableResponsePayloadModel<InvoiceModel[]>>(this.INVOICES_API_URL, {
        params: this.mapParamsToString(params),
      })
      .pipe(map(res => res.content));
  }
}
