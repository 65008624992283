import { ChangeDetectionStrategy, Component } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  standalone: true,
  selector: 'falcon-dps-footer-info',
  templateUrl: './dps-footer-info.component.html',
  styleUrls: ['./dps-footer-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [TranslateModule],
})
export class DpsFooterInfoComponent {}
