<div class="d-flex justify-content-center align-items-center align-self-center mt-5 icon-wrapper">
  <i class="falcon-icon falcon-icon-phone falcon-color-primary"></i>
</div>

<h3 class="text-center falcon-color-primary font-weight-bold my-4">
  {{ 'SHARED.HTTP_ERROR_DIALOG.MESSAGE' | translate }}
</h3>

<div class="d-flex justify-content-center p-3">
  <span>{{ 'SHARED.APP_VERSION' | translate }}:</span>&nbsp;
  <code>{{ appVersion }}</code>
</div>

<div class="d-flex flex-column list-gap">
  <button mat-stroked-button color="primary" class="falcon-rounded-btn">
    <a class="d-block text-reset text-decoration-none" [href]="phoneNumberHref">
      {{ 'SHARED.HTTP_ERROR_DIALOG.CALL_US' | translate : { number: phoneNumber } }}
    </a>
  </button>

  <button mat-flat-button color="accent" class="falcon-rounded-btn" (click)="dialogRef.close()">
    {{ 'GENERAL.CLOSE_WINDOW' | translate }}
  </button>
</div>
