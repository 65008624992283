import { ChangeDetectionStrategy, Component, HostBinding, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { MatListModule } from '@angular/material/list';
import { MatButtonModule } from '@angular/material/button';
import { TranslateModule } from '@ngx-translate/core';
import { PhoneNumberFormat } from 'google-libphonenumber';

import { CompanyModel } from '@falcon/app/shared/models';
import { PhoneService } from '@falcon/app/shared/services';

@Component({
  selector: 'falcon-contact-company-actions',
  standalone: true,
  imports: [CommonModule, MatListModule, MatButtonModule, TranslateModule],
  templateUrl: './contact-company-actions.component.html',
  styleUrls: ['./contact-company-actions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContactCompanyActionsComponent {
  @HostBinding('class') hostClasses = ['d-flex', 'flex-column'];

  constructor(
    @Inject(MAT_BOTTOM_SHEET_DATA) public company: CompanyModel,
    public contactCompanyActionsRef: MatBottomSheetRef<ContactCompanyActionsComponent>,
    private phoneService: PhoneService
  ) {}

  private readonly companyPhoneNumber = this.phoneService.parseBelgiumNumber(
    this.company.phoneNumber
  );

  get formattedPhoneNumber() {
    return this.phoneService.format(this.companyPhoneNumber, PhoneNumberFormat.INTERNATIONAL);
  }

  get companyMobilePhoneHref(): string {
    return `tel:${this.formattedPhoneNumber}`;
  }

  get companyWhatsAppHref(): string {
    return `https://wa.me/${this.phoneService.format(
      this.companyPhoneNumber,
      PhoneNumberFormat.E164
    )}`;
  }
}
