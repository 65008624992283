import { ComponentType } from '@angular/cdk/portal';
import {
  Component,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  OnInit,
  AfterViewInit,
  HostBinding,
  ViewEncapsulation,
} from '@angular/core';
import { MatCalendar, MatCalendarCellClassFunction } from '@angular/material/datepicker';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable, Subject } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import moment, { Moment } from 'moment';

@UntilDestroy()
@Component({
  selector: 'falcon-single-date-picker',
  template: `
    <mat-calendar
      #calendar
      [minDate]="minDate"
      [maxDate]="maxDate"
      [dateClass]="dateClass"
      [selected]="selectedDate"
      [headerComponent]="headerComponent"
      (selectedChange)="selectedChange($event)"
    ></mat-calendar>
  `,
  styleUrls: ['./single-date-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class SingleDatePickerComponent implements OnInit, AfterViewInit {
  private readonly _today = moment();

  @Input() selectedDate: Moment | null = null;
  @Input() minDate: Moment | null = this.today;
  @Input() maxDate: Moment | null = null;
  @Input() dateClass!: MatCalendarCellClassFunction<Moment>;
  @Input() headerComponent!: ComponentType<any>;
  @Input() update?: Observable<void>;

  @Output() selectedDateChange = new EventEmitter<Moment | null>();

  private _periodChange = new Subject<Moment>();
  @Output() periodChange = this._periodChange
    .asObservable()
    .pipe(distinctUntilChanged((date1, date2) => date1.isSame(date2, 'date')));

  @ViewChild('calendar') calendar!: MatCalendar<Moment>;

  @HostBinding('class.falcon-single-date-picker') hostClass = true;

  get today(): Moment {
    return this._today.clone();
  }

  ngOnInit(): void {
    this.update?.pipe(untilDestroyed(this)).subscribe(() => {
      this.calendar.updateTodaysDate();
    });
  }

  ngAfterViewInit(): void {
    this.calendar.stateChanges
      .pipe(untilDestroyed(this))
      .subscribe(() => this._periodChange.next(this.calendar.activeDate));
  }

  selectedChange(date: Moment | null): void {
    this.selectedDateChange.emit(date);
  }
}
