import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { SwUpdate } from '@angular/service-worker';
import { HttpClient } from '@angular/common/http';
import {
  distinctUntilChanged,
  filter,
  from,
  map,
  Observable,
  of,
  Subject,
  switchMap,
  tap,
} from 'rxjs';
import { compare } from 'compare-versions';

import { environment } from '@falcon/env';
import { AppRoutePath } from '@falcon/app/app.routing.model';
import {
  FeatureFlagKeysEnum,
  FeatureFlagService,
  SnackBarService,
} from '@falcon/app/shared/services';
import { PlatformDetector } from '../../utils';

const APP_UPDATE_SUCCESS_STORAGE_KEY = 'appUpdateSuccess';

@Injectable({
  providedIn: 'root',
})
export class AppUpdateService {
  private readonly serverAppVersion$ = new Subject<string>();

  constructor(
    private swUpdate: SwUpdate,
    private router: Router,
    private snackbarService: SnackBarService,
    private http: HttpClient,
    private featureFlagService: FeatureFlagService
  ) {}

  // Should be called only once
  initUpdates(): void {
    if (!this.swUpdate.isEnabled) {
      console.warn('SW Update is not enabled.');
    }

    this.serverAppVersion$
      .pipe(
        distinctUntilChanged(),
        map(serverAppVersion => compare(environment.appVersion, serverAppVersion, '<')),
        tap(isUpdateAvailable => console.log('isUpdateAvailable: ', isUpdateAvailable)),
        filter(Boolean),
        // When update available, check if its required depending on platform
        switchMap(
          () =>
            PlatformDetector.isWeb
              ? of(true) // Web app is always required to have a newest version
              : this.featureFlagService.getFlag$<boolean>(
                  FeatureFlagKeysEnum.NATIVE_UPDATE_REQUIRED
                ) // Native app is required to have an update only if feature flag is turned on
        )
      )
      .subscribe(isUpdateRequired => {
        console.log('isUpdateRequired: ', isUpdateRequired);
        if (isUpdateRequired) {
          this.router.navigateByUrl(AppRoutePath.UPDATE_REQUIRED);
        } else if (this.router.url.includes(AppRoutePath.UPDATE_REQUIRED)) {
          this.router.navigateByUrl(AppRoutePath.ROOT);
        }
      });

    this.getServerAppVersion().subscribe();

    if (localStorage.getItem(APP_UPDATE_SUCCESS_STORAGE_KEY)) {
      this.snackbarService.openAppUpdateSuccess().subscribe();

      localStorage.removeItem(APP_UPDATE_SUCCESS_STORAGE_KEY);
    }
  }

  setServerAppVersion(version: string): void {
    this.serverAppVersion$.next(version);
  }

  updateWebApp(): Observable<boolean> {
    return from(this.swUpdate.checkForUpdate()).pipe(
      switchMap(() => this.swUpdate.activateUpdate()),
      tap(() => localStorage.setItem(APP_UPDATE_SUCCESS_STORAGE_KEY, '+')),
      tap(() => location.assign(AppRoutePath.ROOT))
    );
  }

  getServerAppVersion(): Observable<string> {
    return this.http.get<string>(`${environment.publicApiURL}/client/version`);
  }
}
