<button
  mat-icon-button
  #tooltipBtn="matTooltip"
  [matTooltip]="'SHARED.SUCCESSFUL_COPY_TOOLTIP' | translate"
  [matTooltipDisabled]="true"
  [matTooltipPosition]="'above'"
  (click)="copyContent($event)"
>
  <i class="falcon-icon falcon-icon-copy-content"></i>
</button>
