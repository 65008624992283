import { DictionaryItem, FlashApplicationModel } from '@falcon/shared/models';

export const dictionaryItemTrackByFn = (_index: number, item: DictionaryItem): string => {
  return item.code;
};

export const flashAppTrackByFn = (_index: number, flashApp: FlashApplicationModel): string => {
  return flashApp.id;
};

export const dictionaryItemCompareFn = (option: DictionaryItem, value: DictionaryItem): boolean => {
  return option?.code === value?.code;
};
