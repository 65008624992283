import { ChangeDetectionStrategy, Component, HostBinding, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';
import { BehaviorSubject, switchMap, take } from 'rxjs';

import { RootStore } from '@falcon/app/core/storage';
import { DialogService } from '@falcon/app/shared/services';
import { FlashApplicationModel, FlashApplicationStatusEnum } from '@falcon/app/shared/models';
import { AppRoutePath } from '@falcon/app/app.routing.model';
import { CompanyRoutePath } from '@falcon/app/modules/company/company.routing.model';
import { CompanyPlanRoutePath } from '@falcon/app/modules/company/modules/plan/company-plan.routing.model';
import { FlashService } from '@falcon/app/core/api';

@Component({
  selector: 'falcon-accept-flash-employee',
  templateUrl: './accept-flash-employee.component.html',
  styleUrls: ['./accept-flash-employee.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AcceptFlashEmployeeComponent {
  @HostBinding('class') hostClasses = ['d-flex', 'flex-column', 'h-100'];
  readonly isLoading$ = new BehaviorSubject(false);

  constructor(
    @Inject(MAT_DIALOG_DATA) public flashApp: FlashApplicationModel,
    private dialogService: DialogService,
    private translateService: TranslateService,
    private router: Router,
    private rootStore: RootStore,
    private flashService: FlashService
  ) {}

  get flashFormattedDatetime(): string {
    const { date, startTime, endTime } = this.flashApp.flash;

    return moment(date).format(`dddd D MMMM, ${startTime} - ${endTime}`);
  }

  get employeeFullName(): string {
    const { firstName, lastName } = this.flashApp.employee;

    return firstName.concat(' ', lastName);
  }

  acceptEmployee(): void {
    this.isLoading$.next(true);

    this.flashService
      .changeFlashApplicationStatus(this.flashApp.id, FlashApplicationStatusEnum.CONFIRMED)
      .pipe(
        switchMap(() => this.rootStore.getCompanyData$()),
        take(1)
      )
      .subscribe(company => {
        this.router.navigate([
          AppRoutePath.COMPANY,
          company.id,
          CompanyRoutePath.PLAN,
          CompanyPlanRoutePath.EMPLOYEES,
        ]);
        this.dialogService.matDialog.closeAll();
        this.dialogService.openSuccessDialog({
          data: {
            title: this.translateService.instant(
              'ACCEPT_EMPLOYEE_FOR_FLASH.CONTRACT_CREATED_SUCCESSFULLY'
            ),
            iconName: 'hand',
          },
        });
      });
  }
}
