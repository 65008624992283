import { CommonModule } from '@angular/common';
import { Component, ChangeDetectionStrategy, Inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';

import { environment } from '@falcon/env';
import { ApiErrorResponse } from '@falcon/shared/models';

@Component({
  standalone: true,
  selector: 'falcon-admin-error-dialog',
  templateUrl: './admin-error-dialog.component.html',
  styleUrls: ['./admin-error-dialog.component.scss'],
  imports: [CommonModule, MatDialogModule, MatButtonModule, TranslateModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdminErrorDialogComponent {
  readonly appVersion = environment.appVersion;

  constructor(@Inject(MAT_DIALOG_DATA) public error: ApiErrorResponse) {}
}
