import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatDatepickerModule } from '@angular/material/datepicker';

import { DateRangePickerComponent } from './date-range-picker.component';

@NgModule({
  imports: [CommonModule, MatDatepickerModule],
  exports: [DateRangePickerComponent],
  declarations: [DateRangePickerComponent],
})
export class DateRangePickerModule {}
