<ion-app>
  <ng-container *ngIf="isOldDomainUsed; else appRouterOutlet">
    <main class="d-flex flex-column h-100 falcon-bg-primary p-4">
      <img
        src="assets/images/worktoday-logo.svg"
        alt="worktoday-logo"
        class="w-50 align-self-center"
      />

      <section class="d-flex flex-fill align-items-center">
        <div class="info-block">
          <span>We hebben een nieuwe naam:</span>
          <span>Work Today</span>
          <br />
          <span>Je gebruikt nog de oude versie.</span>
          <br />
          <span>Gelieve deze app te verwijderen en te herinstalleren.</span>
          <br />
          <span>Hulp nodig?</span>
          <a
            [href]="'https://work-today-installation.webflow.io/'"
            target="_blank"
          >
            Volg de stappen hier
          </a>
          <br />
          <span>
            Of bel
            <a [href]="'tel:' + supportPhoneNumber">{{ supportPhoneNumber }}</a>
          </span>
        </div>
      </section>
    </main>
  </ng-container>
</ion-app>

<ng-template #appRouterOutlet>
  <router-outlet></router-outlet>
</ng-template>
