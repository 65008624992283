import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '@falcon/env';
import { BaseApi } from '@falcon/core/api/models';
import { LabourAssignmentModel } from '@falcon/shared/models';
import { LabourAssignmentsRequestParamsModel } from './labour-assignments-request-params.model';

@Injectable({
  providedIn: 'root',
})
export class LabourAssignmentsService extends BaseApi {
  private readonly LABOUR_ASSIGNMENTS_API_URL = `${environment.apiURL}/labourassignments`;

  constructor(private http: HttpClient) {
    super();
  }

  getLabourAssignments(
    params: LabourAssignmentsRequestParamsModel
  ): Observable<LabourAssignmentModel[]> {
    return this.http.get<LabourAssignmentModel[]>(this.LABOUR_ASSIGNMENTS_API_URL, {
      params: this.mapParamsToString(params),
    });
  }
}
