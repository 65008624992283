import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Share } from '@capacitor/share';
import { Clipboard } from '@capacitor/clipboard';
import { catchError, from, map, of, retry, switchMap, tap, throwError } from 'rxjs';

import { EmployeeInvitationModel } from '@falcon/shared/models';
import { AppRoutePath } from '@falcon/app/app.routing.model';
import { SnackBarService } from '../snack-bar';
import { environment } from '@falcon/env';

@Injectable({
  providedIn: 'root',
})
export class ShareService {
  constructor(private snackbarService: SnackBarService, private platform: Platform) {}

  shareEmployeeInvitation(invitation: EmployeeInvitationModel) {
    const registrationLink = [environment.baseUrl, AppRoutePath.INVITATIONS, invitation.id].join(
      '/'
    );
    const message = `${invitation.company.name} nodigt je uit om je te registreren als werknemer. Zo ben je zeker dat je contract in orde is en je loon nadien correct uitbetaald wordt.\nVolg deze link om je te registreren.`;
    const invitationText = [message, registrationLink].join('\n');

    return from(Share.canShare()).pipe(
      map(res => res.value),
      switchMap(canShare =>
        canShare && !this.platform.is('desktop')
          ? from(Share.share({ text: invitationText })).pipe(
              catchError(err =>
                err?.message?.includes('Share canceled') ? of(null) : throwError(() => err)
              )
            )
          : from(Clipboard.write({ string: invitationText })).pipe(
              switchMap(() => this.snackbarService.openCopySuccess())
            )
      ),
      retry(1)
    );
  }
}
