import { Inject, Injectable, InjectionToken } from '@angular/core';
import { Router } from '@angular/router';
import { ContractRoutePathParam } from '@falcon/app/modules/contract';

export interface NavigationOption<T> {
  path: T;
  fullPath: string;
  [key: string]: unknown;
}

export type NavigationConfig<T extends string, R extends NavigationOption<T>> = Record<T, R>;

export const NAVIGATION_CONFIG_TOKEN = new InjectionToken<
  NavigationConfig<string, NavigationOption<string>>
>('NAVIGATION_CONFIG');

@Injectable()
export class NavigationService<T extends string, R extends NavigationOption<T>> {
  private readonly navigationOptions: R[];
  private _currNavOption!: R;

  get isFirstStep(): boolean {
    return Boolean(this._currNavOption?.path === this.navigationOptions[0].path);
  }

  get currNavOption(): R {
    return this._currNavOption;
  }

  constructor(
    private router: Router,
    @Inject(NAVIGATION_CONFIG_TOKEN) private readonly navigationConfig: NavigationConfig<T, R>
  ) {
    this.navigationOptions = Object.values(navigationConfig);
  }

  setCurrentStep(path: T): void {
    this._currNavOption = this.navigationConfig[path];
  }

  navigateToPrevStep(contractId?: string): void {
    let prevStepPath = this.getPrevStepPath();

    if (prevStepPath && contractId) {
      prevStepPath = prevStepPath?.replace(`:${ContractRoutePathParam.CONTRACT_ID}`, contractId);
    }

    if (prevStepPath) {
      this.router.navigateByUrl(prevStepPath);
    }
  }

  navigateToNextStep(): void {
    const nextStepPath = this.getNextStepPath();

    if (nextStepPath) {
      this.router.navigateByUrl(nextStepPath);
    }
  }

  getPrevStepPath(): string | null {
    return this.navigationOptions[this.findCurrentStepIndex() - 1]?.fullPath || null;
  }

  getNextStepPath(): string | null {
    return this.navigationOptions[this.findCurrentStepIndex() + 1]?.fullPath || null;
  }

  private findCurrentStepIndex(): number {
    return this.navigationOptions.findIndex(option => option.path === this._currNavOption.path);
  }
}
