<mat-form-field>
  <mat-label>{{ label }}</mat-label>

  <input matInput type="text" autocorrect="off" [formControl]="control" [matAutocomplete]="auto" />
  <mat-autocomplete #auto="matAutocomplete" [displayWith]="dictionaryItemDisplayFn">
    <mat-option *ngFor="let item of filteredItems$ | async" [value]="item">
      {{ item.name }}
    </mat-option>
  </mat-autocomplete>
  <mat-error *ngIf="control.hasError('required') && requiredErrorLabel">
    {{ requiredErrorLabel }}
  </mat-error>
  <mat-error *ngIf="control.hasError(unselectedItemErrorName)">
    {{ 'SHARED.DICTIONARY_ITEM_AUTOCOMPLETE.UNSELECTED_ITEM' | translate }}
  </mat-error>
</mat-form-field>
