import {
  CompensationHourCodeEnum,
  DictionaryItem,
  TravelAllowanceCodeEnum,
} from '@falcon/app/shared/models';

export const MIN_WAGE = 8.2;

export const DEFAULT_COMPENSATION_HOUR_ITEM: DictionaryItem<CompensationHourCodeEnum> = {
  code: CompensationHourCodeEnum.NONE,
  name: '',
};
export const DEFAULT_TRAVEL_ALLOWANCE_ITEM: DictionaryItem<TravelAllowanceCodeEnum> = {
  code: TravelAllowanceCodeEnum.NONE,
  name: '',
};
export const DEFAULT_MEAL_VOUCHERS_EMPLOYEE_SHARE = 1.09;
