<mat-form-field>
  <mat-label>{{ label }}</mat-label>

  <input
    type="email"
    autocorrect="off"
    matInput
    [placeholder]="placeholder"
    [formControl]="control"
  />
  <mat-error *ngIf="control.hasError('required') && requiredErrorLabel">{{
    requiredErrorLabel
  }}</mat-error>

  <mat-error *ngIf="control.hasError(emailErrorName)">{{
    'VALIDATORS_ERRORS.INVALID_EMAIL' | translate
  }}</mat-error>
</mat-form-field>
