import { RootState } from './root.state';
import { Store } from '../store';
import { Observable } from 'rxjs';
import {
  distinctUntilChanged,
  distinctUntilKeyChanged,
  filter,
  map,
  shareReplay,
  switchMap,
} from 'rxjs/operators';

import { CoefficientsConfig, CompanyDetailModel, MandateModel } from '@falcon/shared/models';

export class RootStore extends Store<RootState> {
  constructor() {
    super(new RootState());
  }

  setCompany(company: CompanyDetailModel): void {
    this.update({ company });
  }

  getCompany$(): Observable<CompanyDetailModel | null> {
    return this.select$(state => state.company);
  }

  getCompanyData$(): Observable<CompanyDetailModel> {
    return this.getCompany$().pipe(
      filter(Boolean),
      map(company => company as CompanyDetailModel),
      shareReplay(1)
    );
  }

  clearCompany(): void {
    this.update({ company: null });
  }

  setCompanyContractListWeekStart(weekStartDate: string): void {
    const currCompanyId = this.select(state => state.company?.id);
    if (!currCompanyId) return;

    const existingContractListWeekStarts = this.select(
      state => state.companiesContractListWeekStart
    );

    this.update({
      companiesContractListWeekStart: {
        ...existingContractListWeekStarts,
        [currCompanyId]: weekStartDate,
      },
    });
  }

  getCompanyContractListWeekStart$(): Observable<string | null> {
    return this.getCompanyData$().pipe(
      switchMap(company => this.select$(state => state.companiesContractListWeekStart[company.id]))
    );
  }

  setCompanyConfirmationListWeekStart(weekStartDate: string): void {
    const currCompanyId = this.select(state => state.company?.id);
    if (!currCompanyId) return;

    const existingConfirmationListWeekStarts = this.select(
      state => state.companiesConfirmationListWeekStart
    );

    this.update({
      companiesConfirmationListWeekStart: {
        ...existingConfirmationListWeekStarts,
        [currCompanyId]: weekStartDate,
      },
    });
  }

  getCompanyConfirmationListWeekStart$(): Observable<string | null> {
    return this.getCompanyData$().pipe(
      switchMap(company =>
        this.select$(state => state.companiesConfirmationListWeekStart[company.id])
      )
    );
  }

  setMandate(mandate: MandateModel): void {
    this.update({ mandate });
  }

  getMandate$(): Observable<MandateModel | null> {
    return this.select$(state => state.mandate);
  }

  getMandateData$(): Observable<MandateModel> {
    return this.getMandate$().pipe(
      filter(Boolean),
      map(mandate => mandate as MandateModel),
      distinctUntilKeyChanged('status'),
      shareReplay(1)
    );
  }

  clearMandate(): void {
    this.update({ mandate: null });
  }

  recountGlobalUnconfirmedActuals(): void {
    this.select(state => state.recountGlobalUnconfirmedActualsTrigger).next();
  }

  getGlobalUnconfirmedActualsTrigger$(): Observable<void> {
    return this.select(state => state.recountGlobalUnconfirmedActualsTrigger).asObservable();
  }

  isCompanyCoefficientsSet$(): Observable<boolean> {
    return this.getCompanyData$().pipe(
      map(company => !!company.coefficientsPerStatute),
      distinctUntilChanged()
    );
  }

  getCoefficientsConfig$(): Observable<CoefficientsConfig> {
    return this.select$(state => state.coefficientsConfig).pipe(filter(Boolean));
  }

  setCoefficientsConfig(config: CoefficientsConfig): void {
    this.update({
      coefficientsConfig: config,
    });
  }
}
