import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  HostListener,
  Input,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { TranslateModule } from '@ngx-translate/core';

import { AcceptFlashEmployeeComponent } from '@falcon/app/modules/company/modules/plan/components/accept-flash-employee/accept-flash-employee.component';
import { FlashApplicationModel } from '../../models';
import { DialogService } from '../../services';

@Component({
  standalone: true,
  imports: [CommonModule, TranslateModule, MatButtonModule],
  selector: 'app-flash-candidate-card',
  templateUrl: './flash-candidate-card.component.html',
  styleUrls: ['./flash-candidate-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FlashCandidateCardComponent {
  @Input() application!: FlashApplicationModel;
  @Input() isActionButtonsShown = true;

  @HostBinding('class') hostClasses = ['falcon-card', 'flex-column'];

  @HostListener('click', ['$event']) onClick = (event: Event) => {
    this.openAcceptEmployeeForFlashPage(event);
  };

  readonly travelDistanceFormat = '1.2-2';

  constructor(private dialogService: DialogService) {}

  openAcceptEmployeeForFlashPage(event: Event) {
    if (!this.isActionButtonsShown) return;

    event.stopPropagation();
    this.dialogService.openFullScreenDialog(AcceptFlashEmployeeComponent, {
      data: this.application,
    });
  }
}
