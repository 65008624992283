import { Component, ChangeDetectionStrategy, HostBinding } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { TranslateModule } from '@ngx-translate/core';
import { PhoneNumberFormat, PhoneNumberUtil } from 'google-libphonenumber';

import { environment } from '@falcon/env';
import { WT_SUPPORT_PHONE_NUMBER } from '@falcon/app/shared/constants';

@Component({
  standalone: true,
  selector: 'falcon-http-error-dialog',
  templateUrl: './http-error-dialog.component.html',
  styleUrls: ['./http-error-dialog.component.scss'],
  imports: [MatButtonModule, TranslateModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HttpErrorDialogComponent {
  readonly appVersion = environment.appVersion;
  private readonly phoneNumberUtil = PhoneNumberUtil.getInstance();

  readonly phoneNumber: string = this.phoneNumberUtil.format(
    WT_SUPPORT_PHONE_NUMBER,
    PhoneNumberFormat.NATIONAL
  );
  readonly phoneNumberHref: string = `tel:${this.phoneNumberUtil.format(
    WT_SUPPORT_PHONE_NUMBER,
    PhoneNumberFormat.INTERNATIONAL
  )}`;

  @HostBinding('class.http-error-dialog') hostClass = true;

  constructor(public dialogRef: MatDialogRef<HttpErrorDialogComponent>) {}
}
