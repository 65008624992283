export enum InvoiceStatusEnum {
  CREATED = 'CREATED',
  PROCESSING = 'PROCESSING',
  PAID = 'PAID',
  REFUSED = 'REFUSED',
  PAST_DUE = 'PAST_DUE',
  CREDIT_NOTES = 'CREDIT_NOTES',
  NEGATIVE = 'NEGATIVE',
  UNCOLLECTED = 'UNCOLLECTED',
}

export interface InvoiceModel {
  id: string;
  invoiceNumber: string;
  dateIssued: string;
  total: number;
  subTotal: number;
  vat: number;
  paymentLink: string;
  updatedAt: string;
  dateDue: string;
  status: InvoiceStatusEnum;
}
