<mat-dialog-content class="d-flex flex-column">
  <i class="falcon-icon falcon-icon-warning_triangle falcon-color-error"></i>
  <h2 mat-dialog-title class="text-center falcon-color-primary font-weight-bold">
    {{ 'SHARED.ADMIN_ERROR_DIALOG.TITLE' | translate }}
  </h2>
  <ul *ngIf="error" class="pl-3 errors-list">
    <li *ngFor="let error of error.errors">{{ error.details }}</li>
  </ul>
</mat-dialog-content>

<div class="d-flex justify-content-center p-3">
  <span>{{ 'SHARED.APP_VERSION' | translate }}:</span>&nbsp;
  <code>{{ appVersion }}</code>
</div>

<button mat-flat-button mat-dialog-close color="accent" class="falcon-rounded-btn w-100">
  {{ 'GENERAL.CLOSE_WINDOW' | translate }}
</button>
