<div class="d-flex justify-content-between">
  <div class="d-flex flex-column">
    <h3 class="mb-1 title">
      {{ application.employee.firstName }} {{ application.employee.lastName }}
    </h3>
    <span *ngIf="application.travelDistance as distance">{{
      'APPLICATIONS.TRAVEL_DISTANCE'
        | translate : { value: distance / 1000 | number : travelDistanceFormat }
    }}</span>
    <span>
      {{
        'FLASH_CARD.GROSS_HOURLY_WAGE'
          | translate : { wage: application.actualWageValue | currency }
      }}
    </span>
  </div>

  <div class="d-flex flex-column">
    <div class="badge position-static falcon-color-gray">
      {{ application.propitiousStatute?.name }}
    </div>

    <div *ngIf="isActionButtonsShown" class="actions-block align-self-end mt-3">
      <button mat-mini-fab (click)="openAcceptEmployeeForFlashPage($event)">
        <i class="falcon-icon falcon-icon-check"></i>
      </button>
    </div>
  </div>
</div>

<div
  *ngIf="
    application.flash.grossHourlyWageValue < application.actualWageValue &&
    !application.flash.grossHourlyWage
  "
  class="d-flex"
>
  <i class="falcon-color-warning falcon-icon falcon-icon-exclamation_circle mr-2"></i>
  <span class="wage-warning falcon-color-gray-text">
    {{ 'APPLICATIONS.HIRE_WAGE_WARNING' | translate }}
  </span>
</div>
