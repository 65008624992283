import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { TranslateModule } from '@ngx-translate/core';

import { AddressInputComponent } from './address-input.component';
import { AddressFormComponent } from './components/address-form/address-form.component';
import { DictionaryItemAutocompleteModule } from '../dictionary-item-autocomplete';

@NgModule({
  declarations: [AddressInputComponent, AddressFormComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatInputModule,
    MatButtonModule,
    MatDialogModule,
    TranslateModule,
    DictionaryItemAutocompleteModule,
  ],
  exports: [AddressInputComponent],
})
export class AddressInputModule {}
