import { Injectable } from '@angular/core';
import { PhoneNumber, PhoneNumberFormat, PhoneNumberUtil } from 'google-libphonenumber';

export const BElGIUM_REGION_CODE = 'BE';

@Injectable()
export class PhoneService extends PhoneNumberUtil {
  isBelgiumNumber(phone: string | null): boolean {
    return !!phone && phone.length > 8 && (phone.startsWith('+32') || phone.startsWith('0'));
  }

  parseBelgiumNumber(phone: string): PhoneNumber {
    return this.parse(phone, BElGIUM_REGION_CODE);
  }

  tryToFormatNumberToBelgian(phone: string): string {
    try {
      const belgianPhoneNumber: PhoneNumber = this.parseBelgiumNumber(phone);
      return this.isValidNumber(belgianPhoneNumber)
        ? this.format(belgianPhoneNumber, PhoneNumberFormat.INTERNATIONAL)
        : phone;
    } catch (error) {
      return phone;
    }
  }
}
