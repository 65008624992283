import { Injectable } from '@angular/core';
import { initialize, LDClient, LDFlagValue } from 'launchdarkly-js-client-sdk';
import { BehaviorSubject, Observable } from 'rxjs';

import { environment } from '@falcon/env';
import { FeatureFlagKeysEnum } from './feature-flag.enums';

export function featureFlagFactoryInit(featureFlagService: FeatureFlagService) {
  return () => featureFlagService.initialize();
}

const FEATURE_FLAGS_DEFAULT_VALUES: Record<FeatureFlagKeysEnum, any> = {
  [FeatureFlagKeysEnum.NATIVE_UPDATE_REQUIRED]: false,
  [FeatureFlagKeysEnum.PWA_DEPRECATION]: false,
};

@Injectable({
  providedIn: 'root',
})
export class FeatureFlagService {
  client!: LDClient;

  initialize(): Promise<boolean> {
    this.client = initialize(environment.featureFlagClientId, { anonymous: true });
    return this.client
      .waitForInitialization()
      .then(() => true)
      .catch(() => {
        console.error('LaunchDarkly could NOT be initialized!');
        return true;
      });
  }

  getFlag$<T = LDFlagValue>(flagKey: FeatureFlagKeysEnum): Observable<T> {
    const flagValue$ = new BehaviorSubject<T>(
      this.client.variation(flagKey, FEATURE_FLAGS_DEFAULT_VALUES[flagKey])
    );
    this.client.on(`change:${flagKey}`, value => flagValue$.next(value));
    return flagValue$.asObservable();
  }
}
