<i
  *ngIf="data.iconName"
  class="falcon-icon falcon-color-primary mt-3"
  [ngClass]="'falcon-icon-' + data.iconName"
></i>

<h2 *ngIf="data.header" class="text-center mt-3 mb-0 falcon-color-primary font-weight-bold">
  {{ data.header }}
</h2>

<h2 class="text-center falcon-color-primary font-weight-bold">
  {{ data.title }}
</h2>

<button mat-flat-button mat-dialog-close color="accent" class="falcon-rounded-btn mt-5">
  {{ data.btnText ? data.btnText : 'OK' }}
</button>
