import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '@falcon/env';
import { EmployeeInvitationModel, PageableResponsePayloadModel } from '@falcon/shared/models';
import { EmployeeInvitationsRequestParamsModel } from './employee-invitations-request-params.model';
import { BaseApi } from '../models';

@Injectable({
  providedIn: 'root',
})
export class EmployeeInvitationService extends BaseApi {
  private EMPLOYEE_INVITATIONS_API_URL = `${environment.apiURL}/employees/invitations`;

  get public(): EmployeeInvitationService {
    this.EMPLOYEE_INVITATIONS_API_URL = `${environment.publicApiURL}/employees/invitations`;
    return this;
  }

  constructor(private http: HttpClient) {
    super();
  }

  createInvitation(payload: EmployeeInvitationModel): Observable<EmployeeInvitationModel> {
    return this.http.post<EmployeeInvitationModel>(this.EMPLOYEE_INVITATIONS_API_URL, payload);
  }

  getInvitation(invitationId: string): Observable<EmployeeInvitationModel> {
    return this.http.get<EmployeeInvitationModel>(
      `${this.EMPLOYEE_INVITATIONS_API_URL}/${invitationId}`
    );
  }

  getInvitations(
    params: EmployeeInvitationsRequestParamsModel
  ): Observable<PageableResponsePayloadModel<EmployeeInvitationModel[]>> {
    return this.http.get<PageableResponsePayloadModel<EmployeeInvitationModel[]>>(
      this.EMPLOYEE_INVITATIONS_API_URL,
      { params: this.mapParamsToString(params) }
    );
  }

  getInvitationCodeLink(invitationId: string): string {
    return `${this.EMPLOYEE_INVITATIONS_API_URL}/${invitationId}/codeLink`;
  }
}
