import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseApi } from '@falcon/core/api/models';
import {
  EmployeeDetailsModel,
  EmployeeModel,
  PageableResponsePayloadModel,
} from '@falcon/shared/models';
import { environment } from '@falcon/env';
import { EmployeesListRequestParamsModel } from './employees-list-request-params.model';

@Injectable({
  providedIn: 'root',
})
export class EmployeeService extends BaseApi {
  private readonly EMPLOYEES_API_URL = `${environment.apiURL}/employees`;

  constructor(private http: HttpClient) {
    super();
  }

  getEmployees(params: EmployeesListRequestParamsModel): Observable<EmployeeModel[]> {
    return this.http
      .get<PageableResponsePayloadModel<EmployeeModel[]>>(this.EMPLOYEES_API_URL, {
        params: this.mapParamsToString(params),
      })
      .pipe(map(resp => resp.content));
  }

  getEmployee(id: string): Observable<EmployeeDetailsModel> {
    return this.http.get<EmployeeDetailsModel>(`${this.EMPLOYEES_API_URL}/${id}`);
  }

  createEmployee(socialSecurityNumber: string, companyId: string): Observable<EmployeeModel> {
    return this.http.post<EmployeeModel>(this.EMPLOYEES_API_URL, {
      socialSecurityNumber,
      companyId,
    });
  }

  updateEmployee(uuid: string, body: EmployeeDetailsModel): Observable<EmployeeDetailsModel> {
    return this.http.put<EmployeeDetailsModel>(
      `${this.EMPLOYEES_API_URL}/${uuid}`,
      this.mapBodyEmptyStringToNull(body)
    );
  }
}
