import { Store } from '../store';
import { AuthState } from '../auth/';
import { UserDetailsModel, UserRolesEnum } from '@falcon/shared/models';
import { filter, Observable } from 'rxjs';

export class AuthStore extends Store<AuthState> {
  constructor() {
    super(new AuthState());
  }

  setUser(user: UserDetailsModel): void {
    this.update({
      user,
      isFullAdmin: this.hasRoles([UserRolesEnum.FULL_ADMIN], user),
      isAdmin: this.hasRoles(
        [
          UserRolesEnum.FULL_ADMIN,
          UserRolesEnum.SUPER_ADMIN,
          UserRolesEnum.SALES_ADMIN,
          UserRolesEnum.PAYROLL_ADMIN,
          UserRolesEnum.PAYROLL_SALES,
          UserRolesEnum.PAYROLL_MANAGEMENT,
          UserRolesEnum.RECRUITER,
          UserRolesEnum.CREDIT_CONTROLLER,
        ],
        user
      ),
      isPayrollSales: this.hasRoles([UserRolesEnum.PAYROLL_SALES], user),
      isCreditController: this.hasRoles([UserRolesEnum.CREDIT_CONTROLLER], user),
      isCompanyUser: this.hasRoles([UserRolesEnum.COMPANY_USER], user),
      isEmployeeUser: this.hasRoles([UserRolesEnum.EMPLOYEE_USER], user),
    });
  }

  getUser(): UserDetailsModel | null {
    return this.select(state => state.user);
  }

  getUser$(): Observable<UserDetailsModel | null> {
    return this.select$(state => state.user);
  }

  getUserData$(): Observable<UserDetailsModel> {
    return this.select$(state => state.user).pipe(filter(Boolean));
  }

  isFullAdmin(): boolean {
    return this.select(state => state.isFullAdmin);
  }
  isAdmin(): boolean {
    return this.select(state => state.isAdmin);
  }
  isPayrollSales(): boolean {
    return this.select(state => state.isPayrollSales);
  }
  isCreditController(): boolean {
    return this.select(state => state.isCreditController);
  }
  isCompanyUser(): boolean {
    return this.select(state => state.isCompanyUser);
  }
  isEmployeeUser(): boolean {
    return this.select(state => state.isEmployeeUser);
  }

  hasRoles(neededRoles: UserRolesEnum[], user?: UserDetailsModel): boolean {
    const roles = user?.roles || this.getUser()?.roles || [];

    return neededRoles.some(neededRole => roles.includes(neededRole));
  }
}
