import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { ContractRoutePath, ContractRoutePathParam } from './contract.routing.model';
import { contractCreationGuard } from './modules/create-contract/create-contract.guard';

const ROUTES: Routes = [
  {
    path: ContractRoutePath.CREATE,
    loadChildren: () => import('./modules/create-contract').then(m => m.CreateContractModule),
    canMatch: [contractCreationGuard],
  },
  {
    path: `:${ContractRoutePathParam.CONTRACT_ID}`,
    children: [
      {
        path: ContractRoutePath.CONSULT,
        loadComponent: () =>
          import('./modules/consult-contract').then(c => c.ConsultContractComponent),
      },
      {
        path: ContractRoutePath.EDIT,
        loadChildren: () => import('./modules/edit-contract').then(m => m.EditContractModule),
      },
      {
        path: ContractRoutePath.CANCEL,
        loadChildren: () => import('./modules/cancel-contract').then(m => m.CancelContractModule),
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(ROUTES)],
  exports: [RouterModule],
})
export class ContractRoutingModule {}
