export enum CompanyRoutePath {
  PLAN = 'plan',
  CONTRACTS = 'contracts',
  CONFIRMATIONS = 'confirmations',
  INVOICES = 'invoices',
  PROFILE = 'profile',
  FUNCTIONS = 'functions',
}

export enum CompanyFunctionsRoutePathParam {
  CREATE_FUNCTION = 'create',
  FUNCTION_ID = 'functionId',
}
