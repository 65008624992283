import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { Clipboard } from '@capacitor/clipboard';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltip, MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'falcon-copy-button',
  standalone: true,
  imports: [CommonModule, MatButtonModule, MatTooltipModule, TranslateModule],
  templateUrl: './copy-button.component.html',
  styleUrls: ['./copy-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class CopyButtonComponent {
  @Input() content!: string;
  @Input() tooltipDuration = 2000;
  @ViewChild('tooltipBtn') tooltipBtn!: MatTooltip;
  @HostBinding('class') hostClasses = ['d-inline-block', 'falcon-copy-button-component'];

  copyContent(event: Event): void {
    if (!this.content) return;

    event.stopPropagation(); // Do not focus field if copy button is located inside it
    Clipboard.write({ string: this.content }).then(this.showSuccessfulCopyTooltip.bind(this));
  }

  private showSuccessfulCopyTooltip(): void {
    this.tooltipBtn.disabled = false;
    this.tooltipBtn.show();

    setTimeout(() => {
      this.tooltipBtn.hide();
      this.tooltipBtn.disabled = true;
    }, this.tooltipDuration);
  }
}
