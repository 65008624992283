import { DictionaryItem } from './dictionary.model';

export interface EmployeeInvitationModel {
  id: string;
  companyId: string;
  company: {
    id: string;
    name: string;
    vat: string;
    vatCountryCode: string;
  };
  referenceName: string;
  functions: EmployeeInvitationFunctionModel[];
  status: EmployeeInvitationStatusEnum;
  createdAt: string;
  expiredAt: string;
}

export interface EmployeeInvitationFunctionModel {
  id: string;
  companyFunctionId: string;
  name: string;
  hasMinWage: boolean;
  wageHour: number | null;
  mealVoucher: EmployeeInvitationMealVoucherModel | null;
  status: InvitationFunctionStatusEnum | null;
  statute: DictionaryItem;
}

export interface EmployeeInvitationMealVoucherModel {
  shareEmployee: number;
  shareCompany: number;
  shareTotal: number;
}

export enum EmployeeInvitationStatusEnum {
  ACTIVE = 'ACTIVE',
  COMPLETED = 'COMPLETED',
  CANCELED = 'CANCELED',
  EXPIRED = 'EXPIRED',
}

export enum InvitationFunctionStatusEnum {
  COMPANY_FUNCTION_DELETED = 'COMPANY_FUNCTION_DELETED',
}
