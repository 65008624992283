<falcon-search-field
  [placeholder]="'COMPANY.GENERAL.SEARCH_COMMITTEE' | translate"
  (search)="pcCodeSearchValue$.next($event)"
></falcon-search-field>

<mat-dialog-content class="falcon-bg-pre-white p-3 border-top border-bottom">
  @if (filteredCodes$ | async; as pcCodes) {
    @for (pcCode of pcCodes; track pcCode.code) {
      <div
        matRipple
        class="falcon-card font-semibold align-items-center p-2"
        [mat-dialog-close]="pcCode"
      >
        <span>
          {{ pcCode.code }}
        </span>
        <span class="flex-grow-1 pl-2">
          {{ pcCode.name }}
        </span>
        <button mat-mini-fab color="primary" class="shadow-none">
          <i class="falcon-icon falcon-icon-add"></i>
        </button>
      </div>
    }
  } @else {
    <div class="d-flex justify-content-center">
      <mat-spinner></mat-spinner>
    </div>
  }
</mat-dialog-content>

<button
  color="primary"
  class="falcon-rounded-btn w-75 align-self-center"
  mat-stroked-button
  mat-dialog-close
>
  {{ 'GENERAL.CLOSE_WINDOW' | translate }}
</button>
