import { Moment } from 'moment';

import {
  ContractDetailModel,
  CompanyDetailModel,
  EmployeeDetailsModel,
  ScheduleItem,
} from '@falcon/shared/models';

export class EditContractState {
  contract: ContractDetailModel | null = null;
  company: CompanyDetailModel | null = null;
  employee: EmployeeDetailsModel | null = null;
  dateFrom: Moment | null = null;
  dateTo: Moment | null = null;
  pauseItems: ScheduleItem[] = [];
  scheduleItems: ScheduleItem[] = [];
}
