import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { TranslateModule } from '@ngx-translate/core';

import { DictionaryItemAutocompleteComponent } from './dictionary-item-autocomplete.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatInputModule,
    MatAutocompleteModule,
    TranslateModule,
  ],
  declarations: [DictionaryItemAutocompleteComponent],
  exports: [DictionaryItemAutocompleteComponent],
})
export class DictionaryItemAutocompleteModule {}
