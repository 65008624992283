import { CdkStepper } from '@angular/cdk/stepper';
import { ChangeDetectionStrategy, Component, HostBinding } from '@angular/core';

@Component({
  selector: 'falcon-link-function-stepper',
  templateUrl: './link-function-stepper.component.html',
  styleUrls: ['./link-function-stepper.component.scss'],
  providers: [{ provide: CdkStepper, useExisting: LinkFunctionStepperComponent }],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LinkFunctionStepperComponent extends CdkStepper {
  @HostBinding('class') hostClasses = ['d-flex', 'flex-column', 'overflow-hidden'];
}
