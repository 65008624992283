import { IConfig } from 'ngx-mask';

export const SSN_MASK = '00.00.00-000.00';

export const IBAN_PATTERNS: IConfig['patterns'] = {
  L: { pattern: new RegExp('[a-zA-Z]') },
  D: { pattern: new RegExp('[0-9]') },
  C: { pattern: new RegExp('[a-zA-Z0-9]') },
};

// Optional characters somewhy dont work, need to generate all possible IBAN masks variants
const generateIbanMask = (): string => {
  const masksVariantsSeparator = '||';
  const countryCodeMask = 'LL';
  const checkDigitsMask = 'DD';
  const minCharsCount = 12;
  const maxCharsCount = 16;

  return Array(maxCharsCount + 1)
    .fill(0)
    .map((_, i) => `${countryCodeMask}${checkDigitsMask}${'C'.repeat(minCharsCount + i)}`)
    .join(masksVariantsSeparator);
};
export const IBAN_MASK = generateIbanMask();

export const VAT_MASK = ' 0000.000.000';
