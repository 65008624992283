import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '@falcon/env';
import { BaseApi } from '@falcon/app/core/api/models';
import {
  CompanyFunctionDetailModel,
  FunctionPositionModel,
  FunctionSelectionCoefficientsModel,
  FunctionStatuteModel,
} from '@falcon/app/shared/models';
import { CompanyFunctionsRequestParamsModel } from './company-functions-request-params.model';
import { FunctionSelectionCoefficientsRequestParamsModel } from './function-selection-coefficients-request-params.model';

@Injectable({
  providedIn: 'root',
})
export class CompanyFunctionService extends BaseApi {
  private readonly COMPANY_FUNCTIONS_API_URL = `${environment.apiURL}/companyfunctions`;

  constructor(private http: HttpClient) {
    super();
  }

  createCompanyFunction(
    functionData: CompanyFunctionDetailModel
  ): Observable<CompanyFunctionDetailModel> {
    return this.http.post<CompanyFunctionDetailModel>(this.COMPANY_FUNCTIONS_API_URL, functionData);
  }

  updateCompanyFunction(
    functionId: string,
    functionData: CompanyFunctionDetailModel
  ): Observable<CompanyFunctionDetailModel> {
    return this.http.put<CompanyFunctionDetailModel>(
      `${this.COMPANY_FUNCTIONS_API_URL}/${functionId}`,
      functionData
    );
  }

  getCompanyFunction(functionId: string): Observable<CompanyFunctionDetailModel> {
    return this.http.get<CompanyFunctionDetailModel>(
      `${this.COMPANY_FUNCTIONS_API_URL}/${functionId}`
    );
  }

  getCompanyFunctions(
    params: CompanyFunctionsRequestParamsModel
  ): Observable<CompanyFunctionDetailModel[]> {
    return this.http.get<CompanyFunctionDetailModel[]>(this.COMPANY_FUNCTIONS_API_URL, {
      params: this.mapParamsToString(params),
    });
  }

  deleteFunction(functionId: string): Observable<void> {
    return this.http.delete<void>(`${this.COMPANY_FUNCTIONS_API_URL}/${functionId}`);
  }

  getFunctionPositionSuggestions(params: {
    freeText: string;
    pageSize: number;
  }): Observable<FunctionPositionModel[]> {
    return this.http.get<FunctionPositionModel[]>(`${environment.apiURL}/synonyms`, { params });
  }

  getFunctionStatutes(functionId: string): Observable<FunctionStatuteModel[]> {
    return this.http.get<FunctionStatuteModel[]>(
      `${this.COMPANY_FUNCTIONS_API_URL}/${functionId}/statutes`
    );
  }

  getFunctionSelectionCoefficients(
    params: FunctionSelectionCoefficientsRequestParamsModel
  ): Observable<FunctionSelectionCoefficientsModel> {
    return this.http.get<FunctionSelectionCoefficientsModel>(
      `${this.COMPANY_FUNCTIONS_API_URL}/selectioncoefficients`,
      { params: this.mapParamsToString(params) }
    );
  }
}
