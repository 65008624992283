import { Observable } from 'rxjs';

import {
  CompanyDetailModel,
  ContractDetailModel,
  EmployeeDetailsModel,
  ScheduleItem,
} from '@falcon/shared/models';
import { Store } from '../store';
import { EditContractState } from './edit-contract.state';

export type EditContractDates = Pick<EditContractState, 'dateFrom' | 'dateTo'>;

export class EditContractStore extends Store<EditContractState> {
  constructor() {
    super(new EditContractState());
  }

  setContract(contract: ContractDetailModel): void {
    this.update({ contract });
  }

  getContract(): ContractDetailModel | null {
    return this.select(state => state.contract);
  }

  getContract$(): Observable<ContractDetailModel | null> {
    return this.select$(state => state.contract);
  }

  setCompany(company: CompanyDetailModel): void {
    this.update({ company });
  }

  getCompany(): CompanyDetailModel | null {
    return this.select(state => state.company);
  }

  getCompany$(): Observable<CompanyDetailModel | null> {
    return this.select$(state => state.company);
  }

  setData(fullData: Partial<EditContractState>): void {
    this.update(fullData);
  }

  setEmployee(employee: EmployeeDetailsModel): void {
    this.update({ employee });
  }

  getEmployee$(): Observable<EmployeeDetailsModel | null> {
    return this.select$(state => state.employee);
  }

  setDates(dates: EditContractDates): void {
    this.update(dates);
  }

  getDates(): EditContractDates {
    return this.select(state => ({
      dateFrom: state.dateFrom?.clone() || null,
      dateTo: state.dateTo?.clone() || null,
    }));
  }

  getDates$(): Observable<EditContractDates> {
    return this.select$(state => ({
      dateFrom: state.dateFrom?.clone() || null,
      dateTo: state.dateTo?.clone() || null,
    }));
  }

  getScheduleItems(): ScheduleItem[] {
    return this.select(state => [...state.scheduleItems]);
  }

  getScheduleItems$(): Observable<ScheduleItem[]> {
    return this.select$(state => [...state.scheduleItems]);
  }

  setScheduleItems(scheduleItems: ScheduleItem[]) {
    this.update({ scheduleItems });
  }

  getPauseItems(): ScheduleItem[] {
    return this.select(state => [...state.pauseItems]);
  }

  getPauseItems$(): Observable<ScheduleItem[]> {
    return this.select$(state => [...state.pauseItems]);
  }

  setPauseItems(pauseItems: ScheduleItem[]) {
    this.update({ pauseItems });
  }
}
