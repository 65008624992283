import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '@falcon/env';
import {
  EmployeeRegistrationModel,
  EmployeeSelfRegistrationModel,
  EmployeeSummaryModel,
} from '@falcon/app/shared/models';

@Injectable({
  providedIn: 'root',
})
export class EmployeeRegistrationService {
  private readonly EMPLOYEE_REGISTRATION_API_URL = `${environment.publicApiURL}/employees/registrations`;
  private readonly EMPLOYEE_SELF_REGISTRATION_API_URL = `${environment.publicApiURL}/employees/self-registration`;

  constructor(private http: HttpClient) {}

  getEmployee(invitationId: string): Observable<EmployeeRegistrationModel> {
    return this.http.get<EmployeeRegistrationModel>(
      `${this.EMPLOYEE_REGISTRATION_API_URL}/${invitationId}`
    );
  }

  updateEmployee(
    invitationId: string,
    employeeData: EmployeeRegistrationModel
  ): Observable<EmployeeRegistrationModel> {
    return this.http.put<EmployeeRegistrationModel>(
      `${this.EMPLOYEE_REGISTRATION_API_URL}/${invitationId}`,
      employeeData
    );
  }

  selfRegisterEmployee(
    employeeData: EmployeeSelfRegistrationModel
  ): Observable<EmployeeSelfRegistrationModel> {
    return this.http.post<EmployeeSelfRegistrationModel>(
      this.EMPLOYEE_SELF_REGISTRATION_API_URL,
      employeeData
    );
  }

  getEmployeeSummary(employeeId: string): Observable<EmployeeSummaryModel> {
    return this.http.get<EmployeeSummaryModel>(
      `${this.EMPLOYEE_SELF_REGISTRATION_API_URL}/${employeeId}/summary`
    );
  }
}
