import { Injectable } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { PushNotifications } from '@capacitor/push-notifications';
import { Subject, distinctUntilChanged } from 'rxjs';

import { PushNotification, PushNotificationAction } from '@falcon/app/shared/models';

@Injectable({
  providedIn: 'root',
})
export class PushNotificationsService {
  private readonly _registrationToken$ = new Subject<string>();
  private readonly _pnReceived$ = new Subject<PushNotification>();
  private readonly _pnActionPerformed$ = new Subject<PushNotificationAction>();

  readonly registrationToken$ = this._registrationToken$
    .asObservable()
    .pipe(distinctUntilChanged());
  readonly pnReceived$ = this._pnReceived$.asObservable();
  readonly pnActionPerformed$ = this._pnActionPerformed$.asObservable();

  async initNotifications(): Promise<void> {
    if (!Capacitor.isPluginAvailable('PushNotifications')) return;

    let permStatus = await PushNotifications.checkPermissions();

    if (permStatus.receive === 'prompt') {
      permStatus = await PushNotifications.requestPermissions();
    }

    if (permStatus.receive !== 'granted') {
      console.error('User denied permissions!');
    }

    await PushNotifications.addListener('registration', token =>
      this._registrationToken$.next(token.value)
    );
    await PushNotifications.addListener('pushNotificationReceived', notification =>
      this._pnReceived$.next(notification)
    );
    await PushNotifications.addListener('pushNotificationActionPerformed', action =>
      this._pnActionPerformed$.next(action)
    );
    await PushNotifications.register();
  }
}
