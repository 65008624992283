import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

// Updated regular expression to match the new rules
const EMAIL_REGEXP = /^[a-zA-Z0-9!#$%&'*+/=?^_{|}~`-]+(\.[a-zA-Z0-9!#$%&'*+/=?^_{|}~`-]+)*@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z]{2,}$/;
export const EMAIL_ERROR_NAME = 'emailInvalid';

export const emailValidator = (): ValidatorFn => {
  return (control: AbstractControl): ValidationErrors | null => {
    const email: string | null = control.value;
    const error = { [EMAIL_ERROR_NAME]: true };

    if (!email) return null;

    return EMAIL_REGEXP.test(email) ? null : error;
  };
};
