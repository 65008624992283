import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { PushSubscriptionModel } from '@falcon/app/shared/models';
import { environment } from '@falcon/env';

@Injectable({
  providedIn: 'root',
})
export class PushNotificationsApiService {
  private readonly NOTIFICATIONS_API_URL = `${environment.notificationsApiUrl}/notifications`;

  constructor(private http: HttpClient) {}

  subscribeUserToNotifications(
    payload: PushSubscriptionModel,
    userType: 'employee' | 'company'
  ): Observable<PushSubscriptionModel> {
    return this.http.post<PushSubscriptionModel>(
      `${this.NOTIFICATIONS_API_URL}/${userType}`,
      payload
    );
  }
}
