import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, mapTo, shareReplay, map, finalize } from 'rxjs/operators';

import {
  ContractDetailModel,
  PageableResponsePayloadModel,
  ContractDetailListModel,
  ContractEmployeeViewModel,
} from '@falcon/shared/models';
import { environment } from '@falcon/env';
import { BaseApi } from '@falcon/core/api/models';
import { ContractsListRequestParamsModel } from './contracts-list-request-params.model';

@Injectable({
  providedIn: 'root',
})
export class ContractService extends BaseApi {
  readonly CONTRACTS_API_URL = `${environment.apiURL}/contracts`;
  private readonly _processing = new BehaviorSubject<boolean>(false);

  readonly processing$ = this._processing.asObservable().pipe(shareReplay(1));

  constructor(private http: HttpClient) {
    super();
  }

  createContract(contractData: ContractDetailModel): Observable<boolean> {
    this._processing.next(true);

    return this.http.post(this.CONTRACTS_API_URL, contractData).pipe(
      mapTo(true),
      catchError(() => of(false)),
      finalize(() => this._processing.next(false))
    );
  }

  getContracts(params: ContractsListRequestParamsModel): Observable<ContractDetailListModel[]> {
    this._processing.next(true);

    return this.http
      .get<PageableResponsePayloadModel<ContractDetailListModel[]>>(this.CONTRACTS_API_URL, {
        params: this.mapParamsToString(params),
      })
      .pipe(
        map(resp => resp.content),
        finalize(() => this._processing.next(false))
      );
  }

  getContract(contractId: string): Observable<ContractDetailModel> {
    this._processing.next(true);

    return this.http
      .get<ContractDetailModel>(`${this.CONTRACTS_API_URL}/${contractId}`)
      .pipe(finalize(() => this._processing.next(false)));
  }

  getContractEmployeeView(contractId: string): Observable<ContractEmployeeViewModel> {
    this._processing.next(true);

    return this.http
      .get<ContractEmployeeViewModel>(`${this.CONTRACTS_API_URL}/${contractId}/employeeview`)
      .pipe(finalize(() => this._processing.next(false)));
  }

  updateContract(
    contractId: string,
    contractData: ContractDetailModel
  ): Observable<ContractDetailModel> {
    return this.http.put<ContractDetailModel>(
      `${this.CONTRACTS_API_URL}/${contractId}`,
      contractData
    );
  }
}
