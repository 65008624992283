import { Capacitor } from '@capacitor/core';

export class PlatformDetector {
  static get isIos(): boolean {
    return Capacitor.getPlatform() === 'ios';
  }

  static get isAndroid(): boolean {
    return Capacitor.getPlatform() === 'android';
  }

  static get isWeb(): boolean {
    return Capacitor.getPlatform() === 'web';
  }

  static get isNative(): boolean {
    return this.isIos || this.isAndroid;
  }
}
