import {
  Component,
  ChangeDetectionStrategy,
  Input,
  HostBinding,
  OnInit,
  Optional,
  Attribute,
} from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, map, shareReplay, startWith, take } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { DictionaryService } from '@falcon/core/api/dictionary';
import { CompanyDetailModel, DictionaryItem } from '@falcon/shared/models';
import { DialogService } from '@falcon/shared/services/dialog';
import { SearchPCCodeDialogComponent } from './components/search-pccode-dialog';
import { dictionaryItemTrackByFn } from '@falcon/shared/constants';
import { RootStore } from '@falcon/core/storage';

@UntilDestroy()
@Component({
  selector: 'falcon-pccode-select',
  templateUrl: './pccode-select.component.html',
  styleUrls: ['./pccode-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PCCodeSelectComponent implements OnInit {
  private readonly pcCodes$ = this.dictionaryService
    .getDictionaryItems('paritaircomites')
    .pipe(shareReplay(1));
  readonly dictionaryItemTrackByFn = dictionaryItemTrackByFn;
  readonly isLoading$ = new BehaviorSubject<boolean>(true);
  readonly company$ = this.rootStore.getCompanyData$().pipe(untilDestroyed(this));
  @Input() label!: string;
  @Input() control!: FormControl<DictionaryItem[]>;
  @Input() isEditMode!: boolean;
  @Input() isCreateMode!: boolean;
  @Input() disabled!: boolean;
  companyData!: CompanyDetailModel;
  isNoJCEditOrCreate = false;
  controlValue$!: Observable<DictionaryItem[]>;
  isRequired = false;

  get isMultipleMode(): boolean {
    return this.multiple !== null;
  }

  @HostBinding('class.falcon-pccode-select') hostClass = true;

  constructor(
    private dialogService: DialogService,
    private dictionaryService: DictionaryService,
    private rootStore: RootStore,
    @Optional() @Attribute('multiple') private multiple: string
  ) {}

  ngOnInit(): void {
    this.rootStore
      .getCompanyData$()
      .pipe(take(1))
      .subscribe(company => {
        this.companyData = company;
        this.isLoading$.next(false);
      });
    this.isNoJCEditOrCreate =
      (this.isEditMode || this.isCreateMode) && !this.companyData?.paritairComites?.length;
    this.controlValue$ = this.control.valueChanges.pipe(startWith(this.control.value));
    this.isRequired = this.control.hasValidator(Validators.required);

    // TODO: Improve validator setting
    if (!this.isMultipleMode) {
      this.control.addValidators(control =>
        !(control.value as DictionaryItem[]).length ? { invalid: true } : null
      );
      this.control.updateValueAndValidity();
    }
  }

  add(pcCode: DictionaryItem): void {
    this.control.value.push(pcCode);
    this.updateControlState();
  }

  remove(pcCodeIndex: number): void {
    this.control.value.splice(pcCodeIndex, 1);
    this.updateControlState();
  }

  openDialog(): void {
    this.dialogService.matDialog
      .open(SearchPCCodeDialogComponent, {
        data: this.pcCodes$.pipe(
          map(pcCodes => {
            if (this.companyData?.paritairComites && (this.isCreateMode || this.isEditMode)) {
              const existingCodes = pcCodes.map(pcCode => pcCode.code);
              return this.companyData?.paritairComites.filter(pcCode =>
                existingCodes.includes(pcCode.code)
              );
            } else {
              const existingCodes = this.control.value.map(pcCode => pcCode.code);
              return pcCodes.filter(pcCode => !existingCodes.includes(pcCode.code));
            }
          })
        ),
      })
      .afterClosed()
      .pipe(filter(Boolean))
      .subscribe(this.add.bind(this));
  }

  private updateControlState(): void {
    this.control.markAsDirty();
    this.control.markAsTouched();
    this.control.updateValueAndValidity();
  }
}
