import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppUpdateService } from '@falcon/app/shared/services';
import { Observable, tap } from 'rxjs';

const CLIENT_VERSION_HEADER_KEY = 'x-boemm-client-version';

@Injectable()
export class AppVersionInterceptor implements HttpInterceptor {
  constructor(private appUpdateService: AppUpdateService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      tap((event: HttpEvent<any>) => {
        if (!(event instanceof HttpResponse)) return;

        const serverAppVersion = event.headers.get(CLIENT_VERSION_HEADER_KEY);

        if (!serverAppVersion) return;

        this.appUpdateService.setServerAppVersion(serverAppVersion);
      })
    );
  }
}
