import { WeekdayEnum } from './weekday.model';
import { AddressModel } from './address.model';
import { DictionaryItem, ReasonCodeEnum, TaxRateCodeEnum } from './dictionary.model';
import { TravelAllowanceModel } from './employee-wage.model';
import { MealVoucherModel } from './meal-voucher.model';

export interface ContractModel {
  id?: string;
  dateFrom: string;
  dateTo: string;
}

export interface ContractDetailModel extends ContractModel {
  companyId: string;
  employeeId: string;
  allocationId: string;
  parentId: string | null;
  status: ContractStatusEnum;
  previousStatus: ContractStatusEnum | null;
  timetable: TimetableModel;
  officeCode: string;
  consultant: {
    id: string;
  };
  revenueOfficeCode: string;
  revenueConsultant: {
    id: string;
  };
  wageHour: number;
  compensationHours: DictionaryItem | null;
  invoiceEcoWeekly: boolean;
  mealVoucher: MealVoucherModel;
  travelAllowance: TravelAllowanceModel;
  invoicing: ContractInvoicingModel;
  employmentAddress: AddressModel;
  employmentAddressInfo: string | null;
  paritairComite: DictionaryItem;
  statute: DictionaryItem;
  reason: DictionaryItem<ReasonCodeEnum>;
  employeeHoursPerWeek: number;
  companyHoursPerWeek: number;
  cancelReason: string | null;
  cancelExtraInfo: string | null;
  confirmations: ConfirmationsModel[];
  shouldNotifyEmployeeOnCancel: boolean;
  mutualAgreementContractCancellation: MutualAgreementContractCancellationModel;
  isFlashContract: boolean;
}

export interface ConfirmationsModel {
  contractId: string;
  date: string;
  id: string;
  status: string;
}

export interface ContractDetailListModel extends ContractModel {
  status: ContractStatusEnum;
  position: string;
  employee: {
    id: string;
    fullName: string;
  };
  company: {
    id: string;
    name: string;
    phoneNumber: string;
  };
  statute: DictionaryItem;
  timetable: TimetableModel;
}

export interface TimetableModel {
  scheduleItems: ScheduleItem[];
  pauseItems: ScheduleItem[];
}

export interface SingleDayTimetableModel {
  scheduleItem: ScheduleItem;
  pauseItem: ScheduleItem | null;
}

export interface MutualAgreementContractCancellationModel {
  isMutualAgreement: boolean;
  email: string | null;
  cancellationTime: string | null;
}

export interface ScheduleItem {
  dayOfWeek: WeekdayEnum;
  fromTime: string;
  toTime: string;
}

export interface ModernScheduleItemModel {
  date: string;
  workStart: string;
  workEnd: string;
  pauseStart: string | null;
  pauseEnd: string | null;
}

export interface ContractInvoicingModel {
  coefficient: number | null;
  coefficientBankHoliday: number | null;
  coefficientTravelAllowance: number | null;
  coefficientMealVouchers: number | null;
  coefficientEcoVouchers: number | null;
  dimonaCost: number | null;
  defaultTaxRate: DictionaryItem<TaxRateCodeEnum> | null;
}

export interface ContractEmployeeViewModel {
  id: string;
  allocationId: string;
  status: ContractStatusEnum;
  position: string;
  dateFrom: string;
  dateTo: string;
  timetable: TimetableModel;
  wageHour: number;
  statute: DictionaryItem;
  employmentAddress: AddressModel;
  functionDescription: string | null;
  travelDistance: number;
  company: {
    id: string;
    name: string;
  };
}

export enum ContractStatusEnum {
  DRAFT = 'DRAFT',
  VALIDATION = 'VALIDATION',
  PENDING = 'PENDING',
  ACTIVE = 'ACTIVE',
  CANCELLED = 'CANCELLED',
  CANCEL_VALIDATION = 'CANCEL_VALIDATION',
  DELETED = 'DELETED',
  UNDER_REPAIR = 'UNDER_REPAIR',
}
