import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import {
  CompanyModel,
  CompanyDetailModel,
  PageableResponsePayloadModel,
  MandateModel,
  CoefficientsConfig,
} from '@falcon/shared/models';
import { environment } from '@falcon/env';
import { BaseApi, CompanyUserRequestPayload } from '../models';
import { CompaniesRequestParamsModel } from './company-requests-params.model';

@Injectable({
  providedIn: 'root',
})
export class CompanyService extends BaseApi {
  private readonly COMPANIES_API_URL = `${environment.apiURL}/companies`;
  private readonly COMPANY_USERS_API_URL = `${environment.apiURL}/customers/companies`;

  constructor(private http: HttpClient) {
    super();
  }

  getCompany(id: string): Observable<CompanyDetailModel> {
    return this.http.get<CompanyDetailModel>(`${this.COMPANIES_API_URL}/${id}`);
  }

  getCompanies(
    params: CompaniesRequestParamsModel
  ): Observable<PageableResponsePayloadModel<CompanyModel[]>> {
    return this.http.get<PageableResponsePayloadModel<CompanyModel[]>>(this.COMPANIES_API_URL, {
      params: this.mapParamsToString(params),
    });
  }

  updateCompany(uuid: string, body: CompanyDetailModel): Observable<CompanyDetailModel> {
    return this.http.put<CompanyDetailModel>(
      `${this.COMPANIES_API_URL}/${uuid}`,
      this.mapBodyEmptyStringToNull(body)
    );
  }

  addCompanyUser(reqData: CompanyUserRequestPayload): Observable<void> {
    return this.http.post<void>(this.COMPANY_USERS_API_URL, reqData);
  }

  getMandate(uuid: string): Observable<MandateModel> {
    return this.http.get<MandateModel>(`${this.COMPANIES_API_URL}/${uuid}/mandate`);
  }

  addMandate(uuid: string): Observable<void> {
    return this.http.post<void>(`${this.COMPANIES_API_URL}/${uuid}/mandate`, { companyId: uuid });
  }

  getCoefficientsConfig(): Observable<CoefficientsConfig> {
    return this.http.get<CoefficientsConfig>(`${this.COMPANIES_API_URL}/coefficients`);
  }
}
