export interface UserDetailsModel {
  user: {
    id: string;
    fullName: string;
    email: string;
  };
  roles: UserRolesEnum[];
  managedCompanyId: string | null;
  employeeId: string;
  isDraft: boolean;
  isFlashAllowed: boolean;
}

export enum UserRolesEnum {
  FULL_ADMIN = 'FULL_ADMIN',
  SUPER_ADMIN = 'SUPER_ADMIN',
  SALES_ADMIN = 'SALES_ADMIN',
  PAYROLL_ADMIN = 'PAYROLL_ADMIN',
  PAYROLL_SALES = 'PAYROLL_SALES',
  PAYROLL_MANAGEMENT = 'PAYROLL_MANAGEMENT',
  COMPANY_USER = 'COMPANY_USER',
  RECRUITER = 'RECRUITER',
  PREVENTION_ADVISOR = 'PREVENTION_ADVISOR',
  CREDIT_CONTROLLER = 'CREDIT_CONTROLLER',
  EMPLOYEE_USER = 'EMPLOYEE_USER',
}

export enum UserNicheEnum {
  PAYROLL = 'PAYROLL',
  JFX_CONSTRUCT = 'JFX_CONSTRUCT',
  JFX_INDUSTRY = 'JFX_INDUSTRY',
  FALCON = 'FALCON',
  WMR = 'WMR',
}
