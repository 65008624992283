import { Injectable, InjectionToken, ErrorHandler, Inject } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Platform } from '@ionic/angular';
import Rollbar from 'rollbar';

import { environment } from '@falcon/env';
import { AuthStore } from '@falcon/app/core/storage';

const ROLLBAR_CONFIG: Rollbar.Configuration = {
  accessToken: '18f1848a576845b38000112b498160b6',
  captureUncaught: true,
  captureUnhandledRejections: true,
  environment: environment.name,
  autoInstrument: {
    network: true,
    networkResponseHeaders: true,
    networkResponseBody: true,
    networkRequestBody: true,
    log: true,
    dom: true,
    navigation: true,
    connectivity: true,
    contentSecurityPolicy: true,
    errorOnContentSecurityPolicy: true,
  },
  payload: {
    appVersion: environment.appVersion,
    client: {
      javascript: {
        source_map_enabled: true,
        code_version: environment.appVersion,
        guess_uncaught_frames: true,
      },
    },
  },
  // Ignore when:
  // - User cancels Share dialog
  ignoredMessages: ['Share canceled'],
};

@Injectable()
export class RollbarErrorHandler implements ErrorHandler {
  constructor(
    @Inject(RollbarService) private rollbar: Rollbar,
    private platform: Platform,
    private authStore: AuthStore
  ) {
    this.platform.ready().then(() =>
      this.rollbar.configure({
        payload: {
          appPlatforms: this.platform.platforms(),
        },
      })
    );
    this.authStore.getUser$().subscribe(currentUser =>
      this.rollbar.configure({
        payload: {
          person: currentUser
            ? {
                id: currentUser.user.id,
                ...currentUser,
              }
            : undefined,
        },
      })
    );
  }

  handleError(err: any): void {
    this.rollbar.error(...this.sanitizedError(err));
    console.error(err);
  }

  private sanitizedError(error: any): any[] {
    if (error instanceof Error || error instanceof String) {
      return [error];
    } else if (error instanceof HttpErrorResponse) {
      return [error.message, error];
    } else {
      return ['Error', error];
    }
  }
}

export function rollbarFactory() {
  return new Rollbar(ROLLBAR_CONFIG);
}

export const RollbarService = new InjectionToken<Rollbar>('rollbar');
