import { Moment } from 'moment';

import {
  CompanyDetailModel,
  EmployeeModel,
  LabourAssignmentModel,
  ScheduleItem,
} from '@falcon/shared/models';

export class CreateContractState {
  company: CompanyDetailModel | null = null;
  employee: EmployeeModel | null = null;
  dateFrom: Moment | null = null;
  dateTo: Moment | null = null;
  datePicker = {
    selectedTabIndex: 0,
  };
  pauseItems: ScheduleItem[] = [];
  scheduleItems: ScheduleItem[] = [];
  labourAssignment: LabourAssignmentModel | null = null;
}
