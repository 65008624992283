import { NgModule } from '@angular/core';
import { SharedModule } from '@falcon/shared';

import { DialogService } from './dialog.service';

@NgModule({
  imports: [
    // TODO: Replace with injector
    SharedModule,
  ],
  providers: [DialogService],
})
export class DialogModule {}
