import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '@falcon/env';
import { CalculateDistanceModel, EmployeeWageModel, MinWageModel } from '@falcon/shared/models';
import { EmployeeWagesRequestParamsModel, TransportParamsModel } from './employee-wages-request-params.model';
import { BaseApi } from '../models';

@Injectable({
  providedIn: 'root',
})
export class EmployeeWageService extends BaseApi {
  private readonly EMPLOYEE_WAGES_API_URL = `${environment.apiURL}/employeewages`;

  constructor(private http: HttpClient) {
    super();
  }

  createEmployeeWage(wageData: EmployeeWageModel): Observable<EmployeeWageModel> {
    return this.http.post<EmployeeWageModel>(this.EMPLOYEE_WAGES_API_URL, wageData);
  }

  getEmployeeWage(wageId: string): Observable<EmployeeWageModel> {
    return this.http.get<EmployeeWageModel>(`${this.EMPLOYEE_WAGES_API_URL}/${wageId}`);
  }

  updateEmployeeWage(wageId: string, wageData: EmployeeWageModel): Observable<EmployeeWageModel> {
    return this.http.put<EmployeeWageModel>(`${this.EMPLOYEE_WAGES_API_URL}/${wageId}`, wageData);
  }

  getEmployeeWages(params: EmployeeWagesRequestParamsModel): Observable<EmployeeWageModel[]> {
    return this.http.get<EmployeeWageModel[]>(`${this.EMPLOYEE_WAGES_API_URL}`, {
      params: this.mapParamsToString(params),
    });
  }
  deleteEmployeeWage(wageId: string): Observable<void> {
    return this.http.delete<void>(`${this.EMPLOYEE_WAGES_API_URL}/${wageId}`);
  }
  getMinEmployeeWages(): Observable<MinWageModel[]> {
    return this.http.get<MinWageModel[]>(`${this.EMPLOYEE_WAGES_API_URL}/minimal`);
  }
  getTravelAllowance(params: TransportParamsModel): Observable<CalculateDistanceModel> {
    return this.http.get<CalculateDistanceModel>(`${environment.apiURL}/travelallowance/calculate`,
      {
        params: this.mapParamsToString(params),
      });
  }
}
