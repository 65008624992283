import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '@falcon/env';
import { BaseApi } from '../models';
import { DeleteEngagementRequestParamsModel } from './delete-engagement-request-params.model';

@Injectable({
  providedIn: 'root',
})
export class EngagementService extends BaseApi {
  private readonly ENGAGEMENTS_API_URL = `${environment.apiURL}/engagements`;

  constructor(private http: HttpClient) {
    super();
  }

  deleteEngagement(params: DeleteEngagementRequestParamsModel): Observable<void> {
    return this.http.delete<void>(this.ENGAGEMENTS_API_URL, {
      params: this.mapParamsToString(params),
    });
  }
}
