import { CompanyModel } from './company.model';
import { CompanyFunctionModel, FunctionStatuteModel } from './company-function.model';
import {DictionaryItem} from "@falcon/shared/models/dictionary.model";

export interface FlashModel {
  id: string;
  companyId: string;
  company: CompanyModel;
  companyFunctionId: string;
  companyFunction: CompanyFunctionModel;
  date: string;
  startTime: string;
  endTime: string;
  startPauseTime: string | null;
  endPauseTime: string | null;
  description: string | null;
  grossHourlyWage: boolean;
  grossHourlyWageValue: number;
  acceptGeneralTerms: boolean;
  status: FlashStatusEnum;
  travelDistance: number | null;
  flashApplicationsCount: number;
  propitiousWage: FlashPropitiousWageModel | null;
}

export enum FlashStatusEnum {
  ACTIVE = 'ACTIVE',
  CONFIRMED = 'CONFIRMED',
}

export interface FlashPropitiousWageModel {
  statute: FunctionStatuteModel;
  actualWageValue: number;
}

export interface FlashApplicationModel {
  id: string;
  flash: FlashModel;
  employee: {
    id: string;
    firstName: string;
    lastName: string;
  };
  actualWageValue: number;
  propitiousStatute: DictionaryItem | null;
  employeeSummary: string;
  travelDistance: number | null;
  status: FlashApplicationStatusEnum;
}

export interface CancelFlashModel {
  employeeId: string,
  flashId: string
}

export enum FlashApplicationStatusEnum {
  PENDING = 'PENDING',
  CONFIRMED = 'CONFIRMED',
  DECLINED = 'DECLINED',
  CANCELLED = 'CANCELLED',
}
