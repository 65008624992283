import { NgModule } from '@angular/core';
import { DecimalPipe } from '@angular/common';

import { DecimalInputDirective } from './decimal-input.directive';

@NgModule({
  declarations: [DecimalInputDirective],
  exports: [DecimalInputDirective],
  providers: [DecimalPipe],
})
export class DecimalInputModule {}
