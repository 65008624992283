import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpStatusCode,
} from '@angular/common/http';
import { EMPTY, Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { environment } from '@falcon/env';
import { AppRoutePath } from '@falcon/app/app.routing.model';
import { AuthService, AUTH_COOKIE_KEY } from '@falcon/app/core/api/auth';

const AUTH_COOKIE_HEADER = 'x-boemm-skey';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private router: Router, private authService: AuthService) {}

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next
      .handle(
        request.clone({
          headers: request.headers.append(
            AUTH_COOKIE_HEADER,
            localStorage.getItem(AUTH_COOKIE_KEY) || ''
          ),
        })
      )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          const falconApiUrls = [
            environment.apiURL,
            environment.publicApiURL,
            environment.notificationsApiUrl,
          ];
          const isFalconApiRequest = falconApiUrls.some(apiUrl => request.url.includes(apiUrl));

          if (
            isFalconApiRequest &&
            [HttpStatusCode.Unauthorized, HttpStatusCode.GatewayTimeout].includes(error.status)
          ) {
            localStorage.removeItem(AUTH_COOKIE_KEY);

            if (!request.url.includes(this.authService.AUTH_API_URL)) {
              this.router.navigateByUrl(AppRoutePath.AUTH);
              return EMPTY;
            }
          }

          return throwError(() => error);
        })
      );
  }
}
