import {
  AbstractControl,
  UntypedFormControl,
  FormGroupDirective,
  NgForm,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import moment from 'moment';

import { SSNHelper } from '../utils';
import { DateTimeFormats } from '../enums';

export const BIRTHDAY_ERROR_NAME = 'birthdayInvalid';

export const birthdayValidator = (): ValidatorFn => {
  return (control: AbstractControl): ValidationErrors | null => {
    const ssn: string = control.get('socialSecurityNumber')?.value;
    const birthday: string = control.get('dateOfBirth')?.value;

    if (!ssn || !birthday) return null;

    const ssnHelper = new SSNHelper(ssn);
    const birthDate = ssnHelper.getBirthdayDate();

    if (!birthDate.isValid()) return null;

    return birthDate.isSame(moment(birthday, DateTimeFormats.ISO_DATE), 'date')
      ? null
      : { [BIRTHDAY_ERROR_NAME]: true };
  };
};

export class BirthdayErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(
    control: UntypedFormControl | null,
    form: FormGroupDirective | NgForm | null
  ): boolean {
    return control?.invalid || control?.parent?.hasError(BIRTHDAY_ERROR_NAME) || false;
  }
}
