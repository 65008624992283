import { Injectable } from '@angular/core';
import { ComponentType } from '@angular/cdk/portal';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { switchMap } from 'rxjs/operators';

import { HttpErrorDialogComponent } from '@falcon/shared/components/http-error-dialog';
import { SuccessDialogComponent } from '@falcon/shared/components/success-dialog';
import { AdminErrorDialogComponent } from '@falcon/shared/components/admin-error-dialog';
import { ApiErrorResponse } from '@falcon/shared/models';
import {
  GenericConfirmDialog,
  GenericConfirmDialogComponent,
  SuccessDialogDataModel,
} from '@falcon/shared/components';

@Injectable()
export class DialogService {
  constructor(public matDialog: MatDialog, private translateService: TranslateService) {}

  openAdminErrorDialog(errorResp: ApiErrorResponse): MatDialogRef<AdminErrorDialogComponent> {
    return this.matDialog.open(AdminErrorDialogComponent, { data: errorResp });
  }

  openHttpErrorDialog(): MatDialogRef<HttpErrorDialogComponent> {
    return this.matDialog.open(HttpErrorDialogComponent);
  }

  openSuccessDialog(
    config: MatDialogConfig<SuccessDialogDataModel>
  ): MatDialogRef<SuccessDialogComponent> {
    return this.matDialog.open(SuccessDialogComponent, config);
  }

  openGenericConfirmDialog(translationKey: string, config?: MatDialogConfig<GenericConfirmDialog>) {
    return this.translateService.get(translationKey).pipe(
      switchMap(({ TITLE, CONFIRM_BUTTON_TEXT, CANCEL_BUTTON_TEXT }) =>
        this.matDialog
          .open<GenericConfirmDialogComponent, GenericConfirmDialog, boolean>(
            GenericConfirmDialogComponent,
            {
              ...config,
              data: {
                ...config?.data,
                title: TITLE,
                confirmBtnText: CONFIRM_BUTTON_TEXT,
                cancelBtnText: CANCEL_BUTTON_TEXT,
              },
            }
          )
          .afterClosed()
      )
    );
  }

  openFullScreenDialog<T, D>(
    component: ComponentType<T>,
    config?: MatDialogConfig<D>
  ): MatDialogRef<T> {
    const dialogConfig: MatDialogConfig = {
      ...config,
      maxWidth: '100%',
      maxHeight: '100%',
      width: '100%',
      height: '100%',
      panelClass: 'full-screen-dialog',
    };

    return this.matDialog.open(component, dialogConfig);
  }
}
