<h3 *ngIf="headingTranslation" class="text-center">{{ headingTranslation | translate }}</h3>

<mat-nav-list class="sheet-actions-group">
  <a mat-list-item [href]="adminMobilePhoneHref">
    {{ 'CONTACT_ADMIN_ACTIONS.CALL_US' | translate }} {{ adminPhoneNumber }}
  </a>
  <a mat-list-item [href]="adminEmailHref"> {{ 'CONTACT_ADMIN_ACTIONS.EMAIL_US' | translate }} </a>
</mat-nav-list>

<button
  mat-raised-button
  color="primary"
  class="falcon-rounded-btn mt-3"
  (click)="contactAdminActionsRef.dismiss()"
>
  {{ 'GENERAL.CLOSE_WINDOW' | translate }}
</button>
