import { CommonModule } from '@angular/common';
import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { ReactiveFormsModule, UntypedFormControl } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { TranslateModule } from '@ngx-translate/core';

import { EMAIL_ERROR_NAME, emailValidator } from '@falcon/shared/validators';

@Component({
  standalone: true,
  selector: 'falcon-email-input',
  templateUrl: './email-input.component.html',
  styleUrls: ['./email-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, MatInputModule, ReactiveFormsModule, TranslateModule],
})
export class EmailInputComponent implements OnInit {
  @Input() label!: string;
  @Input() placeholder = '';
  @Input() control!: UntypedFormControl;
  @Input() requiredErrorLabel?: string;

  readonly emailErrorName = EMAIL_ERROR_NAME;

  ngOnInit(): void {
    this.control.addValidators(emailValidator());
    this.control.updateValueAndValidity();
  }
}
