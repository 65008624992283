import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';

import { PhoneInputComponent } from './phone-input.component';

@NgModule({
  declarations: [PhoneInputComponent],
  imports: [CommonModule, ReactiveFormsModule, MatInputModule, MatButtonModule],
  exports: [PhoneInputComponent],
})
export class PhoneInputModule {}
