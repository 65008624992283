<span>© WORK TODAY</span>
<span>BE0697.656.959, RPR: Gent, RSZ: 097/1979449-89,</span>
<span>Erkenning: VG. 2242/UC BXL 20081-406-20191121 WALL W.DISP.1981</span>
<span>Maatschappelijke zetel: Antoon Catriestraat 39a, 9031 Drongen</span>

<span>
  <a href="https://www.worktoday.be/nl/privacy-policy" target="_blank">Privacy Policy</a>,
  <a href="https://www.worktoday.be/nl/algemene-voorwaarden" target="_blank">
    {{ 'SHARED.TERMS_OF_SERVICE' | translate }}
  </a>
  &
  <a href="https://www.worktoday.be/nl/cookies" target="_blank">Cookies</a>
</span>
