import { Observable } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

import {
  CompanyDetailModel,
  EmployeeModel,
  LabourAssignmentModel,
  ScheduleItem,
} from '@falcon/shared/models';
import { Store } from '../store';
import { CreateContractState } from './create-contract.state';

export type CreateContractDates = Pick<CreateContractState, 'dateFrom' | 'dateTo'>;

export class CreateContractStore extends Store<CreateContractState> {
  constructor() {
    super(new CreateContractState());
  }

  setCompany(company: CompanyDetailModel): void {
    this.update({ company });
  }

  getCompany(): CompanyDetailModel | null {
    return this.select(state => state.company);
  }

  getCompany$(): Observable<CompanyDetailModel | null> {
    return this.select$(state => state.company).pipe(distinctUntilChanged());
  }

  setEmployee(employee: EmployeeModel): void {
    this.update({ employee });
  }

  getEmployee$(): Observable<EmployeeModel | null> {
    return this.select$(state => state.employee).pipe(distinctUntilChanged());
  }

  setDates(dates: CreateContractDates): void {
    this.update(dates);
  }

  getDates(): CreateContractDates {
    return this.select(state => ({
      dateFrom: state.dateFrom?.clone() || null,
      dateTo: state.dateTo?.clone() || null,
    }));
  }

  setSelectedTabIndex(selectedTabIndex: number) {
    this.update({ datePicker: { selectedTabIndex } });
  }

  getSelectedTabIndex() {
    return this.select(state => state.datePicker.selectedTabIndex);
  }

  getScheduleItems(): ScheduleItem[] {
    return this.select(state => [...state.scheduleItems]);
  }

  getScheduleItems$(): Observable<ScheduleItem[]> {
    return this.select$(state => [...state.scheduleItems]);
  }

  setScheduleItems(scheduleItems: ScheduleItem[]) {
    this.update({ scheduleItems });
  }

  getPauseItems(): ScheduleItem[] {
    return this.select(state => [...state.pauseItems]);
  }

  getPauseItems$(): Observable<ScheduleItem[]> {
    return this.select$(state => [...state.pauseItems]);
  }

  setPauseItems(pauseItems: ScheduleItem[]) {
    this.update({ pauseItems });
  }

  setLabourAssignment(labourAssignment: LabourAssignmentModel) {
    this.update({ labourAssignment });
  }

  getLabourAssignment$(): Observable<LabourAssignmentModel | null> {
    return this.select$(state => state.labourAssignment);
  }

  isMultiDayContract$(): Observable<boolean> {
    return this.select$(state => !state.dateFrom?.isSame(state.dateTo, 'day'));
  }
}
