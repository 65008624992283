<mat-form-field>
  <mat-label>{{ label }}</mat-label>
  <input
    matInput
    type="text"
    autocorrect="off"
    [disabled]="disabled"
    [value]="formattedValue$ | async"
    [required]="isRequiredControl"
    readonly
  />

  <button mat-icon-button matIconSuffix [disabled]="disabled" (click)="onEditAddress()">
    <i class="falcon-icon falcon-icon-edit"></i>
  </button>
</mat-form-field>
