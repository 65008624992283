import { UserDetailsModel } from '@falcon/shared/models';

export class AuthState {
  user: UserDetailsModel | null = null;

  isFullAdmin: boolean = false;
  isAdmin: boolean = false;
  isPayrollSales: boolean = false;
  isCreditController: boolean = false;
  isCompanyUser: boolean = false;
  isEmployeeUser: boolean = false;
}
