import { Component, ChangeDetectionStrategy, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';

import { DictionaryItem } from '@falcon/shared/models';
import { DebounceTypeEnum } from '@falcon/app/shared/enums';

@Component({
  selector: 'falcon-search-pccode-dialog',
  templateUrl: './search-pccode-dialog.component.html',
  styleUrls: ['./search-pccode-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchPCCodeDialogComponent {
  constructor(@Inject(MAT_DIALOG_DATA) private pcCodes$: Observable<DictionaryItem[]>) {}

  readonly pcCodeSearchValue$ = new BehaviorSubject<string>('');
  readonly filteredCodes$: Observable<DictionaryItem[]> = combineLatest([
    this.pcCodes$,
    this.pcCodeSearchValue$,
  ]).pipe(
    debounceTime(DebounceTypeEnum.Typing),
    map(([pcCodes, searchValue]) => pcCodes.filter(this.filterPCCodes(searchValue)))
  );

  private filterPCCodes(value: string): (pccode: DictionaryItem) => boolean {
    return (pccode: DictionaryItem) =>
      Object.values(pccode).some(property => property.toLowerCase().includes(value.toLowerCase()));
  }
}
