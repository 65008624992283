<h2 class="falcon-color-primary font-semibold mb-2">
  {{ label + (isRequired ? '*' : '') }}
</h2>
<section
  *ngIf="
    !companyData?.paritairComites?.length && !control?.value?.length && (isEditMode || isCreateMode)
  "
>
  <span class="falcon-color-gray ng-star-inserted">
    {{ 'COMPANY_FUNCTION.GENERAL.NO_JOINT_COMMITTEE' | translate }}
  </span>
</section>
<div
  class="falcon-card align-items-center font-semibold p-2 mb-2"
  *ngFor="
    let pcCode of controlValue$ | async;
    index as pcCodeIndex;
    trackBy: dictionaryItemTrackByFn
  "
>
  <span>
    {{ pcCode.code }}
  </span>

  <span class="flex-grow-1 pl-2">
    {{ pcCode.name }}
  </span>

  <button mat-icon-button color="warn" (click)="remove(pcCodeIndex)" [disabled]="disabled">
    <i class="falcon-icon falcon-icon-remove"></i>
  </button>
</div>

<button
  color="primary"
  mat-stroked-button
  class="falcon-rounded-btn mt-2"
  (click)="openDialog()"
  [disabled]="(!isMultipleMode && !!control.value.length) || isNoJCEditOrCreate || disabled"
>
  <i class="falcon-icon falcon-icon-add"></i>
  <span>
    {{ 'COMPANY.GENERAL.ADD_COMMITTEE' | translate }}
  </span>
</button>
