import { inject } from '@angular/core';
import { CanMatchFn, Router } from '@angular/router';

import { AppRoutePath } from '@falcon/app/app.routing.model';
import { AuthService } from '../auth.service';

export const authorizedGuard: CanMatchFn = () => {
  const authService = inject(AuthService);
  const router = inject(Router);

  return !authService.isAuthenticated() || router.createUrlTree([AppRoutePath.SEARCH]);
};
