<ng-container *ngIf="weeksPeriod$ | async as weeksPeriod">
  <button
    mat-icon-button
    color="primary"
    class="sm"
    (click)="changeWeek(weeksPeriod.currWeekStart, 'prev')"
  >
    <i class="falcon-icon falcon-icon-chevron-left"></i>
  </button>

  <div class="d-flex flex-grow-1 falcon-color-primary">
    <div class="week">
      <span>{{ 'CONTRACT_LIST_SCREEN.WEEK' | translate }}</span>
      <span>
        {{ weeksPeriod.prevWeekStart | localizedDate : weekDateFormat }}
      </span>
    </div>
    <div class="week falcon-bg-primary falcon-color-white falcon-button-border-radius">
      <span>{{ 'CONTRACT_LIST_SCREEN.WEEK' | translate }}</span>
      <span>
        {{ weeksPeriod.currWeekStart | localizedDate : weekDateFormat }}
      </span>
    </div>
    <div class="week">
      <span>{{ 'CONTRACT_LIST_SCREEN.WEEK' | translate }}</span>
      <span>
        {{ weeksPeriod.nextWeekStart | localizedDate : weekDateFormat }}
      </span>
    </div>
  </div>

  <button
    mat-icon-button
    color="primary"
    class="sm"
    (click)="changeWeek(weeksPeriod.currWeekStart, 'next')"
  >
    <i class="falcon-icon falcon-icon-chevron-left" style="transform: rotate(180deg)"></i>
  </button>
</ng-container>
