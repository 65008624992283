import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';

import { CompanyService } from './company';
import { EmployeeService } from './employee';
import { DictionaryService } from './dictionary';
import { InvoiceService } from './invoices';

@NgModule({
  imports: [HttpClientModule],
  providers: [CompanyService, EmployeeService, DictionaryService, InvoiceService],
})
export class ApiModule {}
