import { Component, ChangeDetectionStrategy, Inject, OnInit, HostBinding } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter, tap } from 'rxjs/operators';
import { isPlainObject } from 'lodash';

import { DictionaryService } from '@falcon/core/api';
import { AddressModel, DictionaryItem } from '@falcon/shared/models';
import { FormControlsOf } from '@falcon/app/shared/utils';
import { AddressFormDialogData } from '../../address-input.component';

type AddressForm = FormControlsOf<AddressModel>;

const BELGIUM_COUNTRY_CODE = 'BE';

const STREET_NAME_PATTERN = /^[a-zÀ-ÖØ-öø-ÿ\d\s-()']+$/i;
const STREET_NUMBER_PATTERN = /^\d+$/;
const PO_BOX_PATTERN = /^[a-zÀ-ÖØ-öø-ÿ\d-'./\\ ]+$/i;
const BELGIUM_POSTAL_CODE_PATTERN = /^[1-9][0-9]{3}$/;
const POSTAL_CODE_PATTERN = /^[a-z\d\s]+$/i;
const CITY_NAME_PATTERN = /^[a-zÀ-ÖØ-öø-ÿ\s-()']+$/i;

@UntilDestroy()
@Component({
  selector: 'falcon-address-form',
  templateUrl: './address-form.component.html',
  styleUrls: ['./address-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddressFormComponent implements OnInit {
  readonly form = this.generateAddressForm();
  readonly countryAutocompleteControl = new FormControl<DictionaryItem | null>(
    null,
    Validators.required
  );
  readonly countries$ = this.getCountries();

  get canSubmitForm(): boolean {
    return this.form.dirty && this.form.valid;
  }

  @HostBinding('class') hostClasses = ['d-flex', 'flex-column'];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: AddressFormDialogData,
    private fb: FormBuilder,
    private dictionaryService: DictionaryService,
    private dialogRef: MatDialogRef<AddressFormComponent, AddressModel>
  ) {}

  ngOnInit(): void {
    const { address, isFormDisabled } = this.data;

    if (address) {
      this.form.patchValue(address);
    }
    if (isFormDisabled) {
      this.form.disable();
      this.countryAutocompleteControl.disable();
    }

    this.countryAutocompleteControl.valueChanges
      .pipe(filter(isPlainObject), untilDestroyed(this))
      .subscribe(countryItem => {
        this.form.controls.country.setValue(countryItem.name);
        this.form.controls.countryCode.setValue(countryItem.code);
        this.form.markAsDirty();
        this.form.markAsTouched();
        this.form.updateValueAndValidity();
      });

    this.form.controls.countryCode.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe(countryCode => {
        const selectedCountryValidator = Validators.pattern(
          countryCode === BELGIUM_COUNTRY_CODE ? BELGIUM_POSTAL_CODE_PATTERN : POSTAL_CODE_PATTERN
        );
        this.form.controls.postalCode.setValidators([
          Validators.required,
          selectedCountryValidator,
        ]);
        this.form.controls.postalCode.updateValueAndValidity();
      });
  }

  confirmAddress(): void {
    if (this.canSubmitForm) {
      this.dialogRef.close(this.form.getRawValue());
    }
  }

  private generateAddressForm() {
    return this.fb.group<AddressForm>({
      street: this.fb.control(null, [Validators.required, Validators.pattern(STREET_NAME_PATTERN)]),
      streetNumber: this.fb.control(null, [
        Validators.required,
        Validators.pattern(STREET_NUMBER_PATTERN),
      ]),
      bus: this.fb.control(null, [Validators.pattern(PO_BOX_PATTERN)]),
      postalCode: this.fb.control(null),
      city: this.fb.control(null, [Validators.required, Validators.pattern(CITY_NAME_PATTERN)]),
      country: this.fb.control(null),
      countryCode: this.fb.control(null),
      latitude: this.fb.control(null),
      longitude: this.fb.control(null),
    });
  }

  private getCountries() {
    return this.dictionaryService.getPublicDictionaryItems('countries').pipe(
      tap(countries => {
        const selectedCountry = countries.find(country =>
          this.data.address
            ? country.code === this.data.address.countryCode
            : country.code === BELGIUM_COUNTRY_CODE
        );

        if (selectedCountry) {
          this.countryAutocompleteControl.setValue(selectedCountry);
        }
      })
    );
  }
}
