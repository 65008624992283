import { AbstractControl, UntypedFormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { isNull, isObjectLike, isString, isUndefined } from 'lodash';

export const errorName = 'oneOfControlsRequired';

export const oneOfControlsRequiredValidator = (): ValidatorFn => {
  return (control: AbstractControl): ValidationErrors | null => {
    const formGroup = control as UntypedFormGroup;

    return Object.keys(formGroup.controls)
      .map(controlKey => formGroup.get(controlKey)?.value)
      .every(isEmptyValue)
      ? { [errorName]: false }
      : null;
  };
};

const isEmptyValue = (value: any): boolean => {
  if (isString(value)) {
    return !value.trim().length;
  }
  if (isObjectLike(value)) {
    return !Object.keys(value).length;
  }
  return isNull(value) || isUndefined(value) || false;
};
