<i *ngIf="data?.iconName as iconName" class="falcon-icon falcon-color-primary" [ngClass]="'falcon-icon-' + iconName"></i>

<h3 class="falcon-color-primary font-semibold text-center my-3">
  {{ data?.title || 'Are you sure you want to proceed?' }}
</h3>

<div class="d-flex action-buttons">
  <button mat-stroked-button color="primary" class="falcon-rounded-btn" [mat-dialog-close]="false">
    {{ data?.cancelBtnText || 'Cancel' }}
  </button>

  <button mat-flat-button color="accent" class="falcon-rounded-btn" [mat-dialog-close]="true">
    {{ data?.confirmBtnText || 'Confirm' }}
  </button>
</div>

