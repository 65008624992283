import { Component, ChangeDetectionStrategy, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { MatButtonModule } from '@angular/material/button';
import { MatListModule } from '@angular/material/list';
import { Router, RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { AppRoutePath, AppRoutePathParam } from '@falcon/app/app.routing.model';
import { InvitationsRoutePath } from '@falcon/app/modules/invitations/invitations.routing.model';
import { CompanyDetailModel, CompanyStatusEnum, UserRolesEnum } from '@falcon/app/shared/models';
import { AuthStore } from '@falcon/core/storage';

@Component({
  selector: 'falcon-add-employee-actions',
  templateUrl: './add-employee-actions.component.html',
  styleUrls: ['./add-employee-actions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, RouterModule, TranslateModule, MatListModule, MatButtonModule],
})
export class AddEmployeeActionsComponent {
  readonly isPayrollSales = this.authStore.hasRoles([UserRolesEnum.PAYROLL_SALES]);
  constructor(
    public bottomSheetRef: MatBottomSheetRef<AddEmployeeActionsComponent>,
    @Inject(MAT_BOTTOM_SHEET_DATA) public company: CompanyDetailModel,
    private router: Router,
    private authStore: AuthStore
  ) {}

  get canInviteEmployee(): boolean {
    return this.company.status !== CompanyStatusEnum.BLOCKED;
  }

  onRegisterEmployee(): void {
    this.router.navigateByUrl(AppRoutePath.CREATE_EMPLOYEE);
    this.bottomSheetRef.dismiss();
  }

  onInviteEmployee(): void {
    this.router.navigate([AppRoutePath.INVITATIONS, InvitationsRoutePath.CREATE_INVITATION]);
    this.bottomSheetRef.dismiss();
  }

  navigateToInvitationsList(): void {
    this.router.navigate([AppRoutePath.INVITATIONS, InvitationsRoutePath.INVITATIONS_LIST], {
      queryParams: {
        [AppRoutePathParam.COMPANY_ID]: this.company.id,
      },
    });
    this.bottomSheetRef.dismiss();
  }
}
